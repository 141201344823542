import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { InputChange } from 'types/events';
import { Alerts } from 'components/SharedStyledComponents';

type ValidationHandler = (value: string) => boolean;

interface Props {
   placeholder?: string;
   size: 'half' | 'full';
   label?: string;
   wrapperMargin?: string;
   errorMessage?: string;
   validationHandler?: ValidationHandler;
   name?: string;
   helperText?: string;
   value?: string;
   isValidHandler?: (isValid: boolean) => any;
   type?: string;
   onChange?: (event: InputChange) => void;
   required?: boolean;
}

function Input({
   placeholder,
   size,
   label,
   wrapperMargin = '0',
   errorMessage,
   validationHandler,
   helperText = '',
   value = '',
   name,
   isValidHandler = () => true,
   type = 'text',
   onChange = () => {},
   required = true,
}: Props) {
   const inputRef = useRef<any>(null);
   const [focused, setFocus] = useState(false);
   const [onBlurError, setOnBlurError] = useState(false);
   const [onChangeError, setOnChangeError] = useState(false);
   const [unMaskPassword, setUnMaskPassword] = useState(false);

   // We track two types of errors, onBlur and onChange.

   // In general we want errors to only show once a user is done typing. We accomplish
   // this by handling the bulk of errors on blur.

   // Some errors, such as weak or common passwords, need to be handled while typing so the
   // user doesn't have to keep leaving the input to trigger the error. These errors
   // we handle in onChange.

   const onBlurIsValid = validationHandler || onBlurIsValidHandlerByType(type, required);
   const onBlurErrorMessage = errorMessage || (label && onBlurErrorMessageByType(type, label));

   const isInitialMount = useRef(true);
   useEffect(() => {
      // We don't want to accidentally mark inputs as valid when we mount.
      // Errors are initialized to false so the user isn't staring at a new form with
      // a bunch of errors. This means if we call the handler on mount it will set
      // everything as being error free and that's not necessarily true!
      //
      // However, if the consumer provides a default value (i.e. value !== '') we do want to tirgger the handler
      // as most consumers depend on this to determine if a form can be submitted.
      if (isInitialMount.current) {
         isInitialMount.current = false;
         if (value !== '') {
            isValidHandler(onBlurIsValid(value));
         }
      } else if (!isInitialMount.current) {
         isValidHandler(type === 'set-password' ? !onBlurError && !onChangeError : !onBlurError);
      }
   }, [value, onChangeError, onBlurError]);

   function onBlurValidation(value: string) {
      setOnBlurError(!onBlurIsValid(value));
   }

   function onChangeValidation(value: string) {
      // Errors *must* be accurate, so if the user solves an onBlur error
      // we go ahead and remove it to prevent confusion
      if (onBlurIsValid(value)) {
         setOnBlurError(false);
      }
   }

   let inputType = type === 'set-password' ? 'password' : type;
   if (inputType === 'password' && unMaskPassword) {
      inputType = 'text';
   }

   // figure out the correct DOM event type for this
   function handleMasking(e: any) {
      e.stopPropagation();
      inputRef.current.focus();
      setUnMaskPassword(!unMaskPassword);
   }

   return (
      <InputLabelWrapper wrapperMargin={wrapperMargin}>
         {label && (
            <Label htmlFor={name} visible={!onBlurError}>
               {label}
               {helperText && ` - ${helperText}`}
            </Label>
         )}
         {(onBlurError || onChangeError) && (
            <StyledErrorMessage as="label">
               {onBlurError && onBlurErrorMessage}
               {onChangeError && ' Your password is too common or easy to guess'}
            </StyledErrorMessage>
         )}
         <InputWrapper size={size}>
            <StyledInput
               name={name}
               value={value}
               ref={inputRef}
               placeholder={focused ? '' : placeholder}
               type={inputType}
               required={required}
               onFocus={() => setFocus(true)}
               onBlur={event => {
                  const { value } = event.target;
                  setFocus(false);
                  onBlurValidation(value);
               }}
               onChange={event => {
                  const { value } = event.target;
                  onChangeValidation(value);
                  onChange(event);
               }}
            />
            {type.includes('password') && value !== '' && renderMaskToggle(unMaskPassword, handleMasking)}
         </InputWrapper>
      </InputLabelWrapper>
   );
}

const renderMaskToggle = (unMaskPassword: boolean, handleMasking: (e: any) => any) => {
   const text = !unMaskPassword ? 'SHOW' : 'HIDE';
   return (
      <MaskToggle type="button" onClick={handleMasking}>
         {text}
      </MaskToggle>
   );
};

const onBlurIsValidHandlerByType = (type: string, required: boolean): ValidationHandler => {
   if (!required) {
      return () => {
         return true;
      };
   }
   switch (type) {
      case 'email':
         return (value: string) => value.includes('@') && value.includes('.');
      case 'set-password':
         return (value: string) => value.length >= 8;
      case 'text':
      case 'password':
      case 'tel':
         return (value: string) => !!value;
      default:
         return () => {
            return true;
         };
   }
};

const onBlurErrorMessageByType = (type: string, label: string) => {
   switch (type) {
      case 'email':
         return 'Please enter a valid email address.';
      case 'text':
      case 'password':
      case 'tel':
         return `${label} is required.`;
      case 'set-password':
         return 'Your password must be at least 8 characters long.';
      default:
         return '';
   }
};

const halfSize = 200;
const fullSize = 400;

const InputLabelWrapper = styled.div`
   margin: ${({ wrapperMargin }: { wrapperMargin: string }) => wrapperMargin};
   position: relative;
`;

let InputWrapper = styled.div`
   margin: 0 auto;
   position: relative;
   max-width: ${({ size = 'full' }: { size: 'half' | 'full' }) => (size === 'half' ? `${halfSize}` : `${fullSize}`)}px;
   width: 100%;
   display: inline-block;
`;
const Label = styled.label`
   color: ${({ theme }) => theme.almostBlack};
   display: ${({ visible }: { visible: boolean }) => (visible ? 'block' : 'none')};
   position: absolute;
   left: 50px;
   top: -30px;

   @media only screen and (max-width: 400px) {
      left: 10px;
   }
`;

const StyledErrorMessage = styled(Alerts.ErrorMessage)`
   padding: 0 5px;
   height: 70px;
   width: auto;
   margin: unset;
   border: unset;
   box-shadow: unset;
   font-style: italic;
   font-weight: bold;
   font-size: unset;
   position: absolute;
   left: 10px;
   top: -50px;

   @media only screen and (min-width: 600px) {
      left: 40px;
   }
`;

const MaskToggle = styled.button`
   font-size: 14px;
   color: ${({ theme }) => theme.secondaryBlue};
   font-weight: bold;
   position: absolute;
   right: 20px;
   top: 0.8em;
   margin: 0;
   padding: 3px 5px;
   border: none;
   background: transparent;
   cursor: pointer;
   user-select: none;

   :focus,
   &::-moz-focus-inner {
      background: ${({ theme }) => theme.primaryWhite};
      color: ${({ theme }) => theme.almostBlack};
      border-radius: 3px;
      border: none;
      text-decoration: underline;
   }
`;

const StyledInput = styled.input.attrs(({ type }) => ({
   type: type,
}))`
   font-size: 0.9em;
   color: ${({ theme }) => theme.almostBlack};
   background: white;
   width: 100%;
   height: 28px;
   margin: 2px 0 35px 0;
   border: 1px solid ${({ theme }) => theme.primaryGray};
   box-sizing: border-box;
   padding: 20px;
   border-radius: 20px;
   ::placeholder {
      color: ${({ theme }) => theme.primaryGray};
      opacity: 0.9;
      font-style: italic;
   }
`;

export default Input;
