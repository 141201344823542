import React, { useEffect, useState, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { ViewContainer, CardLayout } from 'components/SharedStyledComponents';
import { fetchResource } from 'state/actions/ResourceActions';
import { Store } from 'state/store';
import Loading from 'Elements/Loading';
import ResourceCard from 'components/interface/Resource/ResourceCard/ResourceCard';

interface Props extends RouteComponentProps {
   id?: string;
}

const Resource: React.FC<Props> = ({ id }): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const {
      state: { resourceState },
      dispatch,
   } = useContext(Store);

   const fetchSelectedResource = async (): Promise<void> => {
      setLoading(true);
      id && (await fetchResource(dispatch, id));
      setLoading(false);
   };
   useEffect(() => {
      fetchSelectedResource();
   }, [id]);

   return (
      <ViewContainer>
         {loading ? (
            <Loading />
         ) : (
            <Container>{resourceState.selectedResource.id && <ResourceCard fullView {...resourceState.selectedResource} />}</Container>
         )}
      </ViewContainer>
   );
};

const Container = styled.div`
   display: flex;
   max-width: 800px;
   flex-direction: column;
   justify-content: flex-start;
   margin: 0 auto;
   padding-top: 2em;
`;

export default Resource;
