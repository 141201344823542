import React from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import useViewport from 'hooks/useViewport';

interface Props extends RouteComponentProps {
   to: string;
}

// this component could probably be made a lot more flexible in the future
const Navlink: React.FC<Props> = (props): JSX.Element => {
   const [{ width: windowWith }] = useViewport();
   //used to get borderBottom to sit at very top of sibling container
   let activePaddingBottom: string;
   if (windowWith >= 680) {
      activePaddingBottom = '24px';
   } else {
      activePaddingBottom = '16px';
   }
   return (
      <Link
         {...props}
         getProps={({ isPartiallyCurrent }) => ({
            style: {
               fontWeight: isPartiallyCurrent ? 'bold' : 'normal',
               color: isPartiallyCurrent ? '#CC8141' : '#1390DB',
               // borderBottom: isPartiallyCurrent ? '4px solid #CC8141' : 'none',
            },
         })}
      />
   );
};

export default Navlink;
