import styled from 'styled-components';
import Flex from 'Elements/Flex';

/**
 * GENERAL LAYOUT
 */

export const ViewContainer = styled.div`
   margin: 0 auto;
   padding: 0 20px;
   width: 100%;
   max-width: 1200px;
   height: 100%;
   overflow: visible;
`;

/**
 * LOGIN / ONBOARDING
 */
const Container = styled.div`
   border-radius: 12px;
   box-shadow: ${({ theme }) => theme.boxShadow};
   padding: 2.5em;
   min-width: 330px;
   color: ${({ theme }) => theme.almostBlack};
   background: ${({ theme }) => theme.primaryWhite};
   max-width: 600px;
   position: relative;
   margin: 4em auto;

   @media only screen and (min-width: 1000px) {
      width: 70%;
   }

   @media only screen and (max-width: 400px) {
      margin: 2em auto;
      width: 85%;
   }
`;

const HeadingLg = styled.h1`
   color: ${({ theme }) => theme.primaryDarker};
   font-size: 3em;
   font-weight: 900;
   margin: -40px;
   letter-spacing: 0.1em;

   @media only screen and (max-width: 680px) {
      font-size: 2.5em;
      margin: -30px;
   }

   @media only screen and (max-width: 400px) {
      font-size: 2em;
      margin: -20px;
   }
`;
const HeadingMd = styled.h3`
   color: ${({ theme }) => theme.secondaryBlue};
   font-size: 2em;
   margin: 0;
   font-weight: 200;

   @media only screen and (max-width: 680px) {
      font-size: 1.8em;
   }

   @media only screen and (max-width: 400px) {
      font-size: 1.4em;
   }
`;
const Header = styled.div`
   margin: 2em auto 1em auto;
   text-align: center;

   @media only screen and (max-width: 680px) {
      margin: 1.5em auto;
   }

   @media only screen and (max-width: 400px) {
      margin: 0.5em auto 0.7em auto;
   }
`;

const FormContainer = styled.div`
   margin: 0 auto;
   padding: 1rem 2rem;
   border-radius: 5px;
   display: flex;
   flex-direction: column;
   width: 100%;
   text-align: center;
   a {
      color: grey;
      font-size: 0.9em;
      text-decoration: none;
      &:hover {
         color: black;
         cursor: pointer;
      }
   }
   @media only screen and (max-width: 680px) {
      font-size: 1em;
   }

   @media only screen and (max-width: 400px) {
      padding: 0;
   }
`;

const Form = styled.form`
   position: relative;
`;

const InputContainer = styled.div`
   position: relative;
   width: 100%;
   text-align: ${({ textAlign = 'inherit' }: { textAlign?: string }) => textAlign};
`;

const LogoContainer = styled.div`
   margin: 0 auto 3em auto;
   text-align: center;
   height: 50px;
`;

const TextInput = styled.input.attrs(({ placeholder }: { placeholder: string }) => ({
   type: placeholder.toLowerCase() === 'password' ? 'password' : 'text',
   required: true,
   placeholder,
}))`
   border-radius: 20px;
   border: 1px solid ${({ theme }) => theme.primaryGray};
   display: block;
   width: 80%;
   max-width: 400px;
   margin: 20px auto;
   padding: 10px;
   font-size: 15px;
   color: ${({ theme }) => theme.almostBlack};
   padding-left: 30px;

   ::placeholder {
      color: gray;
      font-style: italic;
   }

   &:focus {
      border: 1px solid ${({ theme }) => theme.secondaryBlue};
   }

   @media only screen and (max-width: 1127px) {
      text-indent: 8px;
   }
   @media only screen and (max-width: 680px) {
      padding: 9px;
      width: 90%;
   }

   @media only screen and (max-width: 400px) {
      width: 100%;
      padding: 8px;
      text-indent: 25px;
   }
`;

// this might need to be a reusable component throughout app
const LoginButton = styled.button.attrs({
   type: 'submit',
})`
   border-radius: 20px;
   padding: 10px;
   width: 80%;
   max-width: 400px;
   background: ${({ theme }) => theme.primaryOrange};
   border: none;
   color: ${({ theme }) => theme.primaryWhite};
   margin: 0.8rem auto;
   font-size: 1.2em;
   font-weight: 700;
   &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.secondaryOrange};
   }

   @media only screen and (max-width: 680px) {
      padding: 9px;
      width: 90%;
   }

   @media only screen and (max-width: 400px) {
      padding: 8px;
      width: 100%;
   }
`;

type Color = 'primary-blue' | 'secondary-blue' | 'primary-green' | 'primary-red' | 'primary-orange' | 'primary-gray';

interface ButtonProps {
   fontSize?: string;
   color: Color;
   type?: 'button' | 'submit';
   width?: string;
   margin?: string;
   disabled?: boolean;
   theme: any;
}
export const Button = styled.button.attrs(({ type = 'button', disabled = false }) => ({ type, disabled }))`
   border-radius: 20px;
   padding: 9px;
   border: none;
   font-size: ${({ fontSize = '1em' }: ButtonProps) => fontSize};
   margin: ${({ margin = 'inherit' }: ButtonProps) => margin};
   color: ${({ theme }) => theme.primaryWhite};
   font-weight: bold;
   width: ${({ width = 'auto' }: ButtonProps) => width};
   background-color: ${({ color, theme }: ButtonProps) => {
      switch (color) {
         case 'primary-blue':
            return theme.primaryBlue;
         case 'secondary-blue':
            return theme.secondaryBlue;
         case 'primary-green':
            return theme.primaryGreen;
         case 'primary-red':
            return theme.primaryRed;
         case 'primary-orange':
            return theme.primaryOrange;
         case 'primary-gray':
            return theme.primaryGray;
      }
   }};
   &:hover {
      background-color: ${({ color, theme }: ButtonProps) => {
         switch (color) {
            case 'primary-blue':
               return theme.primaryDarker;
            case 'secondary-blue':
               return theme.primaryBlue;
            case 'primary-green':
               return theme.secondaryGreen;
            case 'primary-red':
               return theme.secondaryRed;
            case 'primary-orange':
               return theme.secondaryOrange;
            case 'primary-gray':
               return theme.tertiaryGray;
         }
      }};
      cursor: pointer;
   }
`;

export const LoginOnboardingComponents = {
   Container,
   Header,
   HeadingLg,
   HeadingMd,
   LoginButton,
   FormContainer,
   Form,
   InputContainer,
   TextInput,
   LogoContainer,
};

/**
 * ONBOARDING QUESTIONS
 */

const Label = styled.label.attrs(({ htmlFor }) => ({ htmlFor }))`
   color: ${({ theme }) => theme.almostBlack};
   display: block;
`;

const QuestionHeader = styled.h4`
   line-height: 1.5;
`;

const CheckboxDivider = styled.div`
   margin: 0 0.5em;
`;

const QuestionContainer = styled(InputContainer)`
   margin: 1em auto;
`;

const OtherInputContainer = styled.div`
   width: 100%;
   margin: 0 auto;
   text-align: center;
   label {
      text-align: left;
      @media only screen and (max-width: 400px) {
         text-indent: 15px;
      }
   }
   @media only screen and (max-width: 400px) {
      text-align: left;
   }
`;

const OtherTextInputLabel = styled.label.attrs(({ htmlFor }) => ({ htmlFor }))`
   display: block;
   margin-left: 45px;
   color: ${({ theme }) => theme.almostBlack};
   font-weight: 500;
   @media only screen and (max-width: 400px) {
      margin: 0;
   }
`;

const OtherTextInput = styled.input.attrs(({ name }) => ({ type: 'text', name }))`
   border-radius: 20px;
   border: 1px solid ${({ theme }) => theme.secondaryGray};
   padding: 6px;
   width: 80%;
   text-indent: 10px;
   margin: 0 auto;
   color: ${({ theme }) => theme.almostBlack};
`;

export const QuestionLayout = {
   Label,
   QuestionHeader,
   CheckboxDivider,
   QuestionContainer,
   OtherInputContainer,
   OtherTextInputLabel,
   OtherTextInput,
};

/**
 * Resource/overview card
 */

const cardMinWidth = '800px';

const ColumnsWrapper = styled.div`
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;

   @media (min-width: 1080px) {
      justify-content: space-between;
   }
`;

const CardColumnContainer = styled.div`
   position: relative;
   display: flex;
   justify-content: flex-start;
   flex-direction: column;
   align-items: center;

   @media (min-width: ${cardMinWidth}) {
      margin: 0 10px;
   }
`;

const CardColumnHeader = styled.div`
   margin: 0 auto;
   text-align: center;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   font-size: 1em;
   height: 60px;
   padding: 0;
   @media only screen and (max-width: 400px) {
      height: unset;
      margin-bottom: 10px;
      font-size: unset;
   }
`;

const CardColumnCategory = styled.h4`
   color: ${({ theme }) => theme.almostBlack};
   font-weight: 600;
   font-size: 1.4em;
   text-align: center;
   line-height: 1.5;
   margin: 0 auto;

   @media only screen and (max-width: 680px) {
      font-size: 1em;
   }

   @media only screen and (max-width: 400px) {
      font-size: 0.9em;
   }
`;

const CardColumn = styled.div`
   max-width: 500px;
   width: 100%;
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: flex-start;

   @media (min-width: 500px) {
      width: 500px;
   }
`;

const CardContainer = styled.div`
   border: 1px solid ${({ theme }) => theme.secondaryGray};
   border-radius: 4px;
   box-shadow: ${({ theme }) => theme.boxShadow};
   width: 100%;
   background-color: ${({ theme }) => theme.primaryWhite};
   padding: 5px 0;
   margin: 0.4em auto 1em auto;
`;

export const CardLayout = {
   ColumnsWrapper,
   CardColumn,
   CardColumnCategory,
   CardColumnContainer,
   CardColumnHeader,
   CardContainer,
};

/**
 * ALERTS
 */

const ErrorMessage = styled.p`
   color: ${({ theme }) => theme.primaryRed};
   border-radius: 3px;
   font-size: 1em;
   border: 1px dotted red;
   padding: 5px;
   text-align: ${({ alignment }: { alignment?: 'center' | 'left' | 'right' }) => (alignment ? alignment : 'left')};
   margin: 0 auto;
   width: 85%;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   box-shadow: ${({ theme }) => theme.boxShadow};
`;

const InfoMessage = styled.div`
   width: 70%;
   margin: 0 auto;
   border-radius: 5px;
   border: 1px solid ${({ theme }) => theme.secondaryGray};
   background-color: ${({ theme }) => theme.primaryWhite};
   color: ${({ theme }) => theme.secondaryBlue};
   padding: 20px;
   text-align: center;
   font-size: 1.2em;
   font-weight: 600;

   @media only screen and (max-width: 400px) {
      width: 85%;
   }
`;

export const Alerts = { ErrorMessage, InfoMessage };

/**
 * FILTERS
 */

export const FilterContainer = styled.div`
   text-align: left;
   padding-left: 1em;
`;
