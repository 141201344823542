import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Flex from 'Elements/Flex';
import Icon, { IconType } from '../icons/IconProvider';
import { InputChange, ButtonClick } from 'types/events';
import { navigate } from '@reach/router';
import { formatQuery } from 'components/views/Categories';

interface Props {
   height?: string | number;
   width?: string | number;
   icon?: boolean;
}

const SearchBar: React.FC<Props> = ({ height = 'initial', icon = true }): JSX.Element => {
   const [search, setSearch] = useState<string>('');

   const submitSearch = useCallback(
      async (e: ButtonClick) => {
         e.preventDefault();
         if (!!search) {
            await navigate(`/search/${formatQuery(search)}`);
         }
      },
      [search]
   );

   useEffect(() => {
      if (!window.location.pathname.startsWith('/search') && Boolean(search)) {
         setSearch('');
      }
   }, [window.location.pathname]);

   return (
      <Flex flexDirection="row" justifyContent="flex-start" height="50px" width="100%" padding="0.5em" position="relative">
         {icon && (
            <Magnifier>
               <Icon type={IconType.magnifier} />
            </Magnifier>
         )}
         <SearchContainer>
            <SearchInput placeholder="Find resources" height={height} value={search} onChange={(e: InputChange) => setSearch(e.target.value)} />
            <Button onClick={submitSearch}>Go</Button>
         </SearchContainer>
      </Flex>
   );
};
export default SearchBar;

const SearchContainer = styled.form`
   position: relative;
   width: 77%;
   text-align: center;
   margin: 0 auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   @media only screen and (max-width: 400px) {
      margin-right: 2.2em;
      width: 80%;
   }
`;

const Magnifier = styled.span`
   position: absolute;
   top: 12px;
   left: 55px;
   z-index: 1;
`;

const SearchInput = styled.input.attrs({ type: 'text' })`
   background: #fcfcfc;
   border: 1px solid #aaa;
   box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
   text-indent: 38px;
   width: 100%;
   font-size: 1.2em;
   border-top-left-radius: 20px;
   border-bottom-left-radius: 20px;
   height: 40px;

   @media only screen and (min-width: 1290px) {
      width: 70%;
   }

   @media only screen and (max-width: 680px) {
      text-indent: 18px;
   }

   @media only screen and (max-width: 400px) {
      text-indent: 9px;
   }
`;

const Button = styled.button.attrs({ type: 'submit' })`
   height: 40px;
   width: 80px;
   border: none;
   background-color: ${({ theme }) => theme.primaryOrange};
   color: white;
   font-weight: bold;
   border-top-right-radius: 20px;
   /* z-index: 100; */
   border-bottom-right-radius: 20px;
   cursor: pointer;
`;
