import React from 'react';
import Vertical from 'assets/karmonos_vertical_logo.png';
import HorizontalLight from 'assets/karmonos_horizontal_logo_light.png';
import HorizontalDark from 'assets/karmonos_horizontal_logo_dark.png';
import Footer from 'assets/karmonos_footer_logo.png';
import styled from 'styled-components';

export enum LogoOptions {
   vertical = 'vertical',
   horizontal_light = 'horizontal_light',
   horizontal_dark = 'horizontal_dark',
   footer = 'footer',
}

interface Props {
   logo: LogoOptions;
   height?: number;
   width?: number;
   className?: string;
}

const Logo: React.FC<Props> = ({ logo, height, width, className = '' }) => {
   const alt = 'Cancer App Finder';
   switch (logo) {
      case LogoOptions.vertical:
         return <KarmonosLogo className={className} src={Vertical} alt={alt} height={height} width={width} />;
      case LogoOptions.horizontal_dark:
         return <KarmonosLogo className={className} src={HorizontalDark} alt={alt} height={height} width={width} />;
      case LogoOptions.horizontal_light:
         return <KarmonosLogo className={className} src={HorizontalLight} alt={alt} height={height} width={width} />;
      case LogoOptions.footer:
         return <KarmonosLogo className={className} src={Footer} alt={alt} height={height} width={width} />;
   }
};
export default Logo;

const KarmonosLogo = styled.img.attrs(({ src, alt }) => ({
   src,
   alt,
}))`
   height: ${({ height }) => (height ? `${height}px` : 'auto')};
   width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;
