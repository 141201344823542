import React from 'react';
import { render } from 'react-dom';
import 'index.css';
import App from 'App';
import * as serviceWorker from 'registerServiceWorker';
import Mixpanel from 'analytics/Mixpanel';
import ThemeWrapper from 'styles/ThemeProvider';
import { StoreProvider } from 'state/store';

Mixpanel.init();

render(
   <ThemeWrapper>
      <StoreProvider>
         <App />
      </StoreProvider>
   </ThemeWrapper>,
   document.getElementById('root')
);

serviceWorker.unregister();
