import FetchWrapper from 'network/FetchWrapper';
import { ResourceEvents as actions } from 'state/constants';
import { Dispatch } from 'state/store';
import AuthTokenManager from 'network/AuthTokenManager';
import { Recommendation, Tag, Resource } from 'network/EstarApi/Resources';

export const fetchRecommended = async (dispatch: Dispatch): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');

   try {
      const response = await FetchWrapper({
         path: '/recommended',
         mode: 'cors',
         token,
      });
      const recommendedResources: Recommendation = await response.json();
      if (recommendedResources) {
         return dispatch({ type: actions.FETCH_RECOMMENDED, payload: recommendedResources });
      } else {
         throw new Error('Something went wrong in gathering recommended resources -- fetchRecommended()');
      }
   } catch (error) {
      console.error(error);
      return dispatch({ type: actions.RESOURCE_ERROR, payload: error });
   }
};

export const fetchCategories = async (dispatch: Dispatch): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   try {
      const response = await FetchWrapper({
         //sort categories alphabetically -- may need to be dynamic in the future
         path: '/tags?_sort=displayName:ASC',
         mode: 'cors',
         token,
      });
      const categories: Tag[] = await response.json();
      if (categories) {
         return dispatch({ type: actions.FETCH_CATEGORIES, payload: categories });
      } else {
         throw new Error('Something went wrong in gathering categories');
      }
   } catch (error) {
      console.error(error);
      return dispatch({ type: actions.RESOURCE_ERROR, payload: error });
   }
};

export const fetchCategory = async (dispatch: Dispatch, tagId: string): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   try {
      const response = await FetchWrapper({
         path: `/resources?tags.id=${tagId}`,
         mode: 'cors',
         token,
      });
      const resources: Resource[] = await response.json();
      if (resources) {
         return dispatch({ type: actions.FETCH_CATEGORY, payload: resources });
      } else {
         throw new Error('Something went wrong in gathering categories');
      }
   } catch (error) {
      console.error(error);
      return dispatch({ type: actions.RESOURCE_ERROR, payload: error });
   }
};

export const fetchResource = async (dispatch: Dispatch, id: string): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   try {
      const response = await FetchWrapper({
         path: `/resources/${id}`,
         mode: 'cors',
         token,
      });
      const resource: Resource = await response.json();
      if (resource) {
         return dispatch({ type: actions.SET_SELECTED_RESOURCE, payload: resource });
      } else {
         throw new Error('Something went wrong in gathering the requested resource');
      }
   } catch (error) {
      console.error(error);
      return dispatch({ type: actions.RESOURCE_ERROR, payload: error });
   }
};

export const fetchSearchResults = async (dispatch: Dispatch, query: string): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   try {
      const response = await FetchWrapper({
         path: `/search/?text=${query}`,
         mode: 'cors',
         token,
      });
      const results: Resource[] = await response.json();
      if (results) {
         return dispatch({ type: actions.SET_SEARCH_RESULTS, payload: { search: query, results } });
      } else {
         throw new Error('Something went wrong in gathering user search results');
      }
   } catch (error) {
      console.error(error);
      return dispatch({ type: actions.RESOURCE_ERROR, payload: error });
   }
};
