import React, { useState, useContext, useEffect } from 'react';
import { QuestionLayout } from 'components/SharedStyledComponents';
import { SetState } from 'types/events';
import { UserMetaEvents as actions } from 'state/constants';
import { CancerType, Pages } from 'state/user-meta';
import { Store } from 'state/store';
import CheckboxGroup from 'components/interface/Form/CheckboxGroup';
import { renderDiagnosisCheckboxes } from './Onboarding';
import useInputValue from 'hooks/useInputValue';
import styled from 'styled-components';
/**
 * QUESTION CONTENTS
 * 8) For which cancer diagnosis are you seeking information? -- checkbox group
 *
 */

interface Props {
   focusedDiagnosis: CancerType;
   setPagesComplete: SetState<Pages>;
}

const PageFive: React.FC<Props> = ({ focusedDiagnosis, setPagesComplete }): JSX.Element => {
   const { dispatch } = useContext(Store);
   //if at least one of the boxes have been checked or the "other" input has a value
   const [isComplete, setIsComplete] = useState<boolean>(false);
   const otherCancerOfInterest = useInputValue('');

   const determinePageCompleteness = () => {
      if (
         Object.values(focusedDiagnosis).some(
            (cancerType: CancerType | any): boolean => cancerType.checked === true || otherCancerOfInterest.value !== ''
         )
      ) {
         setIsComplete(true);
      } else {
         setIsComplete(false);
      }
      setPagesComplete((prevPages: Pages) => ({ ...prevPages, five: isComplete }));
   };

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      determinePageCompleteness();
   }, [focusedDiagnosis, isComplete, otherCancerOfInterest.value]);
   return (
      <QuestionContainer>
         <CheckboxGroup
            question="For which cancer diagnosis are you seeking information? Check all that apply."
            answerChoices={renderDiagnosisCheckboxes(
               focusedDiagnosis,
               { cancerTypes: actions.SET_FOCUSED_DIAGNOSIS, other: actions.SET_FOCUSED_DIAGNOSIS_OTHER },
               dispatch,
               //user is NOT diagnosed, therefore include "Cancer in general" option
               false
            )}
         />
         <ModifiedOtherInputContainer>
            <ModifiedOtherTextInputLabel>Other (please specify)</ModifiedOtherTextInputLabel>
            <OtherTextInput
               {...otherCancerOfInterest}
               onBlur={() =>
                  otherCancerOfInterest.value !== '' &&
                  dispatch({ type: actions.SET_FOCUSED_DIAGNOSIS_OTHER, payload: otherCancerOfInterest.value })
               }
            />
         </ModifiedOtherInputContainer>
      </QuestionContainer>
   );
};

export default PageFive;

const { QuestionContainer, OtherInputContainer, OtherTextInput, OtherTextInputLabel } = QuestionLayout;

const ModifiedOtherInputContainer = styled(OtherInputContainer)`
   text-align: left;
`;

const ModifiedOtherTextInputLabel = styled(OtherTextInputLabel)`
   margin-left: 20px;
`;
