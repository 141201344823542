import React from 'react';
import useViewport from 'hooks/useViewport';
import { displayPosition } from 'types/cssAttributes';
import styled from 'styled-components';

interface Props {
   position?: displayPosition;
}

const Bookmark: React.FC<Props> = ({ position }): JSX.Element => {
   const [windowDimensions] = useViewport();
   let bookmarkSize: string;
   if (windowDimensions.width >= 1100) {
      bookmarkSize = '48';
   } else if (windowDimensions.width <= 1100 && windowDimensions.width >= 400) {
      bookmarkSize = '32';
   } else {
      bookmarkSize = '24';
   }
   return (
      <Container position={position}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={bookmarkSize} height={bookmarkSize}>
            <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z " />
         </svg>
      </Container>
   );
};

export default Bookmark;

const Container = styled.div`
   position: ${({ position }: Props) => position};
`;
