import React, { useContext } from 'react';
import CheckboxGroup from '../Form/CheckboxGroup';
import { CancerStrings } from 'network/EstarApi/Filters';
import { Store } from 'state/store';
import { FilterEvents as actions } from 'state/constants';
import { Cancer } from 'state/user-meta';
import { FilterContainer } from 'components/SharedStyledComponents';
import { FilterKeys } from 'state/reducers/FIlterReducer';

const CancerType: React.FC<{ filterTarget: FilterKeys }> = ({ filterTarget }): JSX.Element => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);

   const handleCheckboxClick = (name: Cancer) => {
      dispatch({ type: actions.TOGGLE_FILTER, payload: { filterTarget, name } });
      return dispatch({ type: actions.BUILD_FILTER_QUERY });
   };

   return (
      <FilterContainer>
         <CheckboxGroup
            answerChoices={[
               {
                  label: CancerStrings.lung,
                  checked: filterState[filterTarget][Cancer.lung].checked,
                  resolver: () => handleCheckboxClick(Cancer.lung),
               },
               {
                  label: CancerStrings.breast,
                  checked: filterState[filterTarget][Cancer.breast].checked,
                  resolver: () => handleCheckboxClick(Cancer.breast),
               },
               {
                  label: CancerStrings.prostate,
                  checked: filterState[filterTarget][Cancer.prostate].checked,
                  resolver: () => handleCheckboxClick(Cancer.prostate),
               },
               {
                  label: CancerStrings.colon_rectum,
                  checked: filterState[filterTarget][Cancer.colon_rectum].checked,
                  resolver: () => handleCheckboxClick(Cancer.colon_rectum),
               },
               {
                  label: CancerStrings.ovarian,
                  checked: filterState[filterTarget][Cancer.ovarian].checked,
                  resolver: () => handleCheckboxClick(Cancer.ovarian),
               },
               {
                  label: CancerStrings.cervical,
                  checked: filterState[filterTarget][Cancer.cervical].checked,
                  resolver: () => handleCheckboxClick(Cancer.cervical),
               },
               {
                  label: CancerStrings.cervix,
                  checked: filterState[filterTarget][Cancer.cervix].checked,
                  resolver: () => handleCheckboxClick(Cancer.cervix),
               },
               {
                  label: CancerStrings.kidney,
                  checked: filterState[filterTarget][Cancer.kidney].checked,
                  resolver: () => handleCheckboxClick(Cancer.kidney),
               },
               {
                  label: CancerStrings.pancreatic,
                  checked: filterState[filterTarget][Cancer.pancreatic].checked,
                  resolver: () => handleCheckboxClick(Cancer.pancreatic),
               },
               {
                  label: CancerStrings.liver,
                  checked: filterState[filterTarget][Cancer.liver].checked,
                  resolver: () => handleCheckboxClick(Cancer.liver),
               },
               {
                  label: CancerStrings.leukemia,
                  checked: filterState[filterTarget][Cancer.leukemia].checked,
                  resolver: () => handleCheckboxClick(Cancer.leukemia),
               },
               {
                  label: CancerStrings.non_hodgkin_lymphoma,
                  checked: filterState[filterTarget][Cancer.non_hodgkin_lymphoma].checked,
                  resolver: () => handleCheckboxClick(Cancer.non_hodgkin_lymphoma),
               },
            ]}
         />
      </FilterContainer>
   );
};

export default CancerType;
