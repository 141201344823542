import React, { useContext, useEffect, useState } from 'react';
import { QuestionLayout } from 'components/SharedStyledComponents';
import { SetState } from 'types/events';
import { UserMetaEvents as actions } from 'state/constants';
import RadioButtonGroup from 'components/interface/Form/RadioButtonGroup';
import { Pages, RequestedResources, RequestedResourcesType, TreatmentStatus, TreatmentStatusType } from 'state/user-meta';
import { Store } from 'state/store';
import CheckboxGroup from 'components/interface/Form/CheckboxGroup';
import useInputValue from 'hooks/useInputValue';

/**
|--------------------------------------------------
| QUESTION CONTENTS
7) How much of your cancer treatment have you completed? -- radio button
       Note: this question will ONLY BE VISIBLE if user selected "Myself" for question 4 (Page 2)
8) What kind of resources are you searching for? -- checkbox group
       Note: this question should always be shown
|--------------------------------------------------
*/

interface Props {
   personal: boolean;
   treatmentStatus: TreatmentStatusType;
   requestedResources: RequestedResourcesType;
   setPagesComplete: SetState<Pages>;
}

const PageFour: React.FC<Props> = ({ personal, treatmentStatus, requestedResources, setPagesComplete }): JSX.Element => {
   const { dispatch } = useContext(Store);
   const [isComplete, setIsComplete] = useState<boolean>(false);
   const otherRequestedResources = useInputValue('');
   const determinePageCompleteness = () => {
      //todo: this conditional logic could probably be cleaned up
      //if at least one of the boxes have been checked or the "other" input has a value
      //if seeking personal resources and there is a radio button selected for treatment status
      if (
         (personal && Object.values(treatmentStatus).some((status: boolean): boolean => status === true)) ||
         otherRequestedResources.value !== ''
      ) {
         setIsComplete(true);
         //if seeking info for someone else and there's at least one box checked
      } else if (
         (!personal && Object.values(requestedResources).some((resource: any): boolean => resource.checked === true)) ||
         otherRequestedResources.value !== ''
      ) {
         setIsComplete(true);
      } else {
         setIsComplete(false);
      }
      setPagesComplete((prevPages: Pages) => ({ ...prevPages, four: isComplete }));
   };

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      determinePageCompleteness();
   }, [personal, treatmentStatus, requestedResources, isComplete, otherRequestedResources.value]);

   return personal ? (
      <RadioButtonGroup
         question="How much of your cancer treatment have you completed?"
         answerChoices={[
            {
               label: 'I have not started treatment but it is planned',
               checked: treatmentStatus.not_started === true,
               resolver: () => dispatch({ type: actions.SET_TREATMENT_STATUS, payload: TreatmentStatus.not_started }),
            },
            {
               label: 'My treatment is ongoing',
               checked: treatmentStatus.ongoing === true,
               resolver: () => dispatch({ type: actions.SET_TREATMENT_STATUS, payload: TreatmentStatus.ongoing }),
            },
            {
               label: 'My treatment is complete',
               checked: treatmentStatus.complete === true,
               resolver: () => dispatch({ type: actions.SET_TREATMENT_STATUS, payload: TreatmentStatus.complete }),
            },
            {
               label: 'No treatment is planned',
               checked: treatmentStatus.not_planned === true,
               resolver: () => dispatch({ type: actions.SET_TREATMENT_STATUS, payload: TreatmentStatus.not_planned }),
            },
         ]}
      />
   ) : (
      <QuestionContainer>
         <CheckboxGroup
            question="What kind of resources are you searching for? Check all that apply."
            answerChoices={[
               {
                  label: RequestedResources.general_info,
                  checked: requestedResources[RequestedResources.general_info].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.general_info }),
               },
               {
                  label: RequestedResources.treatment_specific,
                  checked: requestedResources[RequestedResources.treatment_specific].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.treatment_specific }),
               },
               {
                  label: RequestedResources.cancer_risk_detection_recurrence,
                  checked: requestedResources[RequestedResources.cancer_risk_detection_recurrence].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.cancer_risk_detection_recurrence,
                     }),
               },
               {
                  label: RequestedResources.healthcare_management,
                  checked: requestedResources[RequestedResources.healthcare_management].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.healthcare_management }),
               },
               {
                  label: RequestedResources.symptom_tracking_management_resources,
                  checked: requestedResources[RequestedResources.symptom_tracking_management_resources].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.symptom_tracking_management_resources,
                     }),
               },
               {
                  label: RequestedResources.cancer_in_youth,
                  checked: requestedResources[RequestedResources.cancer_in_youth].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.cancer_in_youth }),
               },
               {
                  label: RequestedResources.community_social_support,
                  checked: requestedResources[RequestedResources.community_social_support].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.community_social_support,
                     }),
               },
               {
                  label: RequestedResources.emotional_spiritual_support,
                  checked: requestedResources[RequestedResources.emotional_spiritual_support].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.emotional_spiritual_support,
                     }),
               },
               {
                  label: RequestedResources.advocacy_fundraising_resources,
                  checked: requestedResources[RequestedResources.advocacy_fundraising_resources].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.advocacy_fundraising_resources,
                     }),
               },
            ]}
         />
         <OtherInputContainer>
            <OtherTextInputLabel>Other (please specify)</OtherTextInputLabel>
            <OtherTextInput
               {...otherRequestedResources}
               onBlur={() =>
                  otherRequestedResources.value !== '' &&
                  dispatch({ type: actions.SET_REQUESTED_RESOURCES_OTHER, payload: otherRequestedResources.value })
               }
            />
         </OtherInputContainer>
      </QuestionContainer>
   );
};

export default PageFour;

const { QuestionContainer, OtherInputContainer, OtherTextInput, OtherTextInputLabel } = QuestionLayout;
