import FetchWrapper from 'network/FetchWrapper';
import { UserEvents as actions } from 'state/constants';
import { Dispatch } from 'state/store';
import AuthTokenManager from 'network/AuthTokenManager';
import { Onboarding, formatOnboardingData } from 'state/user-meta';
import { deriveTruth } from 'utility/ArrayUtils';
import Mixpanel from 'analytics/Mixpanel';

interface Login {
   identifier: string;
   password: string;
}
// user login
export const loginUser = async (dispatch: Dispatch, { identifier, password }: Login): Promise<boolean | undefined> => {
   try {
      const response = await FetchWrapper({
         path: '/auth/local',
         mode: 'cors',
         method: 'POST',
         body: {
            identifier,
            password,
         },
      });
      const { jwt, user } = await response.json();

      AuthTokenManager.setAccessToken(jwt);
      if (!user) {
         throw new Error('Something went wrong when logging in user');
      }

      if (user.profile) {
         fetchProfile(dispatch);
      }

      Mixpanel.identify(user.username);
      Mixpanel.track('Successful login');
      Mixpanel.people.set({
         username: user.username,
      });

      dispatch({ type: actions.LOGIN_USER, payload: user });
      return Boolean(user.profile);
   } catch (error) {
      dispatch({
         type: actions.USER_ERROR,
         payload: 'Something went wrong when attempting to log you in. Please check your credentials and try again',
      });
      console.error(error);
   }
};

export const getUser = async (dispatch: Dispatch): Promise<boolean | undefined> => {
   try {
      const response = await FetchWrapper({
         path: '/user',
         mode: 'cors',
         method: 'GET',
         token: AuthTokenManager.getAccessToken(),
      });
      const { user } = await response.json();

      dispatch({ type: actions.LOGIN_USER, payload: user });
      return Boolean(user.profile);
   } catch (error) {
      console.error(error);
   }
};

export const logoutUser = (dispatch: Dispatch): void => {
   AuthTokenManager.clearTokens();
   Mixpanel.endSession();
   dispatch({ type: actions.LOGOUT_USER });
};

export const fetchFavorites = async (dispatch: Dispatch): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   try {
      const response = await FetchWrapper({
         path: '/favorites',
         mode: 'cors',
         token,
      });
      const favorites = await response.json();
      if (favorites) {
         return dispatch({ type: actions.FETCH_FAVORITES, payload: favorites });
      } else {
         throw new Error('Something went wrong in gathering user favorites');
      }
   } catch (error) {
      console.error(error);
   }
};

export const toggleFavorite = async (resourceId: number, isFavorite: boolean): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   const method = isFavorite ? 'DELETE' : 'PUT';
   try {
      await FetchWrapper({
         path: `/favorites/${resourceId}`,
         method,
         mode: 'cors',
         token,
      });
   } catch (error) {
      console.error(error);
   }
};

export const fetchProfile = async (dispatch: Dispatch): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');

   try {
      const response = await FetchWrapper({
         path: '/profiles',
         mode: 'cors',
         token,
      });
      const profile = await response.json();
      if (profile) {
         dispatch({ type: actions.FETCH_USER_PROFILE, payload: profile });
         return profile;
      } else {
         throw new Error("Something went wrong in retrieving user's profile");
      }
   } catch (error) {
      console.error(error);
   }
};

export const createProfile = async (formData: Onboarding): Promise<Response | any> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');

   return await FetchWrapper({
      path: '/profiles/',
      method: 'POST',
      mode: 'cors',
      body: formatOnboardingData(formData),
      token,
   });
};

export const updateProfile = async (dispatch: Dispatch, resourceSelections: Onboarding): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   const checkBoxData = { props: { target: 'checked', desired: 'id' } };
   try {
      const response = await FetchWrapper({
         method: 'PUT',
         path: '/profiles',
         mode: 'cors',
         body: { resourceSelections: deriveTruth({ obj: resourceSelections, ...checkBoxData }) },
         token,
      });
      const profile = await response.json();
      if (profile) {
         dispatch({ type: actions.UPDATE_USER_PROFILE, payload: profile });
         window.location.reload();
      } else {
         throw new Error("Something went wrong in retrieving user's profile");
      }
   } catch (error) {
      console.error(error);
   }
};
