import React, { Context, createContext, ReactNode, useReducer } from 'react';
import USER_STATE from 'state/reducers/UserReducer';
import RESOURCE_STATE from 'state/reducers/ResourceReducer';
import FILTER_STATE from 'state/reducers/FIlterReducer';
import SIDEBAR_REDUCER from 'state/reducers/SidebarReducer';

export enum StateKeys {
   userState = 'userState',
   resourceState = 'resourceState',
   filterState = 'filterState',
   sidebarState = 'sidebarState',
}

const initialAppState = {
   [StateKeys.userState]: USER_STATE.initialState,
   [StateKeys.resourceState]: RESOURCE_STATE.initialState,
   [StateKeys.filterState]: FILTER_STATE.initialState,
   [StateKeys.sidebarState]: false,
};

const combinedReducers = combineReducers({
   [StateKeys.userState]: USER_STATE.reducer,
   [StateKeys.resourceState]: RESOURCE_STATE.reducer,
   [StateKeys.filterState]: FILTER_STATE.reducer,
   [StateKeys.sidebarState]: SIDEBAR_REDUCER,
});

export const Store: Context<any> = createContext(initialAppState);

export const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
   const [state, dispatch] = useReducer<any>(combinedReducers, initialAppState);
   return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>;
};

export interface Action {
   type: string;
   payload?: any;
}

export type Reducer = (state: any, { type, payload }: Action) => any;
export type Dispatch = (action: Action) => Promise<void> | void;

function combineReducers(reducers: { [reducer: string]: Reducer }): (state: any, action: any) => { state: any } {
   return (state: any = {}, action: Action) => {
      return Object.keys(reducers).reduce((nextState: any, key: any) => {
         nextState[key] = reducers[key](state[key], action);
         return nextState;
      }, {});
   };
}
