import React, { Fragment } from 'react';
import styled from 'styled-components';
import Flex from 'Elements/Flex';
import SearchButtons from './SearchButtons';
import { Link } from '@reach/router';

interface Props {
   title: string;
   resultCount: number;
   width?: string;
   breadCrumbs?: boolean;
}

const PageHeading: React.FC<Props> = ({ title, resultCount, width = '100%', breadCrumbs = false }) => {
   const breadCrumbText = window.location.pathname.split('/')[1];
   const breadCrumbTo = `/${breadCrumbText}`;
   return (
      <Flex flexDirection="row" justifyContent="flex-start" padding="15px 0" alignItems="center" width={width}>
         {breadCrumbs && (
            <Fragment>
               <BreadCrumb to={breadCrumbTo}>{breadCrumbText}</BreadCrumb>
               <Slash>/</Slash>
            </Fragment>
         )}
         <Title length={title.length}>{title} - </Title>
         <Subtitle>{resultCount} Results Found</Subtitle>
         {!/recommended|categories\/?$/g.test(window.location.href) && <SearchButtons />}
      </Flex>
   );
};

export default PageHeading;

const Title = styled.h1`
   color: ${({ theme }) => theme.primaryDarker};
   font-size: ${({ length }: { length: number }) => (length > 50 ? '1.1em' : '1.4em')};
   font-weight: 800;
   display: inline-block;

   @media only screen and (max-width: 400px) {
      font-size: 1.1em;
   }
`;

const Subtitle = styled.h3`
   color: ${({ theme }) => theme.secondaryBlue};
   font-size: 1.1em;
   font-weight: 600;
   margin-left: 4px;
   font-style: italic;
   padding: 2px;
   display: inline-block;

   @media only screen and (max-width: 400px) {
      font-size: 0.9em;
   }
`;

const BreadCrumb = styled(Link)`
   color: ${({ theme }) => theme.secondaryBlue};
   font-size: 1.4em;
   text-transform: capitalize;
`;

const Slash = styled.span`
   color: ${({ theme }) => theme.primaryDarker};
   font-size: 1.4em;
   padding: 0 7px;
`;
