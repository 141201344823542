import { Action, Reducer } from 'state/store';
import { UserEvents, UserMetaEvents as actions } from 'state/constants';
import { initialUserMeta, Cancer, RequestedResources } from 'state/user-meta';
import { reset } from 'utility/ArrayUtils';
import { Resource } from 'network/EstarApi/Resources';

const initialState = {
   currentUser: {
      meta: initialUserMeta,
      profile: {},
      loaded: false,
   },
   favorites: [],
   error: null,
};

// when we define a type/interface for currentUserMeta/currentUser, apply it here
const reducer: Reducer = (state: any = initialState, action: Action) => {
   const { meta } = state.currentUser;
   const { type, payload } = action;
   switch (type) {
      case UserEvents.LOGIN_USER:
         //persist meta
         state.currentUser = { ...state.currentUser, ...payload, loaded: true };
         return state;
      case UserEvents.LOGOUT_USER:
         return { ...state, currentUser: { meta: initialUserMeta, loaded: false }, favorites: [] };
      case UserEvents.FETCH_FAVORITES:
         return { ...state, favorites: payload as Resource[] };
      case UserEvents.FETCH_USER_PROFILE:
         state.currentUser.profile = payload;
         return state;
      case actions.SET_AGE:
         state.currentUser.meta = { ...meta, age: payload as number };
         return state;
      case actions.SET_GENDER:
         state.currentUser.meta = { ...meta, gender: { ...reset(meta.gender), [payload]: true } };
         return state;
      case actions.SET_PREVIOUS_DIAGNOSIS:
         state.currentUser.meta = { ...meta, diagnosedWithCancer: payload as boolean };
         return state;
      case actions.SET_PERSONAL:
         state.currentUser.meta = { ...meta, personal: payload as boolean };
         return state;
      case actions.SET_CAREGIVER:
         state.currentUser.meta = { ...meta, caregiver: payload as boolean };
         return state;
      case actions.SET_DIAGNOSIS:
         //checkbox group so state must be persisted
         const { diagnosis } = state.currentUser.meta;
         state.currentUser.meta.diagnosis = {
            ...diagnosis,
            [payload]: { ...diagnosis[payload], checked: !diagnosis[payload].checked as boolean },
         };
         return state;
      case actions.SET_DIAGNOSIS_OTHER:
         const { diagnosis: otherDiagnosis } = state.currentUser.meta;
         state.currentUser.meta.diagnosis = {
            ...otherDiagnosis,
            [Cancer.other]: {
               ...otherDiagnosis[Cancer.other],
               checked: !otherDiagnosis[Cancer.other].checked as boolean,
               value: payload as string,
            },
         };
         return state;
      case actions.SET_TREATMENT_STATUS:
         state.currentUser.meta = { ...meta, treatmentStatus: { ...reset(meta.treatmentStatus), [payload]: true } };
         return state;
      case actions.SET_FOCUSED_DIAGNOSIS:
         //checkbox group so state must be persisted
         const { focusedDiagnosis } = state.currentUser.meta;
         state.currentUser.meta.focusedDiagnosis = {
            ...focusedDiagnosis,
            [payload]: { ...focusedDiagnosis[payload], checked: !focusedDiagnosis[payload].checked as boolean },
         };
         return state;
      case actions.SET_FOCUSED_DIAGNOSIS_OTHER:
         const { focusedDiagnosis: otherFocusedDiagnosis } = state.currentUser.meta;
         state.currentUser.meta.focusedDiagnosis = {
            ...otherFocusedDiagnosis,
            [Cancer.other]: {
               ...otherFocusedDiagnosis[Cancer.other],
               checked: !otherFocusedDiagnosis[Cancer.other].checked as boolean,
               value: payload as string,
            },
         };
         return state;
      case actions.SET_REQUESTED_RESOURCES:
         const { requestedResources } = state.currentUser.meta;
         //checkbox group so state must be persisted
         state.currentUser.meta.requestedResources = {
            ...requestedResources,
            [payload]: { ...requestedResources[payload], checked: !requestedResources[payload].checked as boolean },
         };
         return state;
      case actions.SET_REQUESTED_RESOURCES_OTHER:
         const { requestedResources: otherRequestedResources } = state.currentUser.meta;
         state.currentUser.meta.requestedResources = {
            ...otherRequestedResources,
            [RequestedResources.other]: {
               ...otherRequestedResources[RequestedResources.other],
               checked: !otherRequestedResources[RequestedResources.other].checked as boolean,
               value: payload as string,
            },
         };
         return state;
      case UserEvents.UPDATE_USER_PROFILE:
         state.currentUser.profile = action.payload;
         return state;
      case UserEvents.USER_ERROR:
         return { ...state, error: action.payload };
      default:
         return state;
   }
};

export default { initialState, reducer };
