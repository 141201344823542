import React, { Fragment, useEffect, useState } from 'react';
import { LoginOnboardingComponents, QuestionLayout } from 'components/SharedStyledComponents';
import Checkbox from 'components/interface/Form/Checkbox';
import Flex from 'Elements/Flex';
import { AnswerChoice } from 'state/user-meta';
import uuid from 'uuid';

interface Props {
   question?: string | undefined;
   answerChoices: AnswerChoice[];
   textAlign?: string;
}

const CheckboxGroup: React.FC<Props> = ({ question, answerChoices, textAlign = 'inherit' }): JSX.Element => {
   const [inputVisible, setInputVisible] = useState<boolean>(false);
   const middleIndex: number = Math.floor(answerChoices.length / 2);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   //this seems silly; open to better solutions for splitting answer choices
   const renderColumnOne = (): (JSX.Element | false)[] =>
      answerChoices.map(
         ({ label, resolver, checked }: AnswerChoice, i: number): JSX.Element | false =>
            i < middleIndex && (
               <InputContainer key={uuid.v4()} textAlign={textAlign}>
                  <Checkbox label={label} checked={checked} onChange={() => resolver()} />
               </InputContainer>
            )
      );

   const renderColumnTwo = (): (JSX.Element | false)[] =>
      answerChoices.map(
         ({ label, resolver, checked }: AnswerChoice, i: number): JSX.Element | false => {
            if (label === 'Other' && checked && !inputVisible) {
               setInputVisible(true);
            }
            return (
               i >= middleIndex && (
                  <InputContainer key={uuid.v4()} textAlign={textAlign}>
                     <Checkbox label={label} checked={checked} onChange={() => resolver()} />
                  </InputContainer>
               )
            );
         }
      );

   return (
      <QuestionContainer>
         {Boolean(question) && <QuestionHeader>{question}</QuestionHeader>}
         <Flex flexDirection="row" justifyContent="space-between" margin="1em auto 0 auto">
            {answerChoices.length > 6 ? (
               <Fragment>
                  <CheckboxDivider>{renderColumnOne()}</CheckboxDivider>
                  <CheckboxDivider>{renderColumnTwo()}</CheckboxDivider>
               </Fragment>
            ) : (
               answerChoices.map(
                  ({ label, resolver, checked }: AnswerChoice): JSX.Element => (
                     <InputContainer key={uuid.v4()} textAlign={textAlign}>
                        <Checkbox label={label} checked={checked} onChange={() => resolver()} />
                     </InputContainer>
                  )
               )
            )}
         </Flex>
      </QuestionContainer>
   );
};

export default CheckboxGroup;

const { QuestionContainer, QuestionHeader, CheckboxDivider } = QuestionLayout;
const { InputContainer } = LoginOnboardingComponents;
