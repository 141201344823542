import { Resource } from 'network/EstarApi/Resources';
import { CancerType } from 'state/user-meta';
import { FilterKeys } from 'state/reducers/FIlterReducer';
export interface SortOptions {
   recommended: boolean;
   alphabetical: boolean;
   reverse_alphabetical: boolean;
   rating: boolean;
   price: boolean;
}

export enum PurposeStrings {
   general_cancer_education = 'General cancer education/information',
   treatment_specific_info = 'Treatment-specific information',
   risk_detection_recurrence = 'Cancer risk, detection, and recurrence',
   healthcare_management = 'Healthcare management (e.g., tracking appointments, communicating with healthcare providers, etc.)',
   symptom_tracking = 'Symptom tracking/management',
   cancer_in_youth = 'Information about cancer in children, adolescents, young adults',
   community_social_support = 'Community/social support',
   spirituality = 'Spirituality/meaning in life',
   advocacy_fundraising = 'Advocacy/fundraising',
}

export interface Purpose {
   [PurposeStrings.general_cancer_education]: { checked: boolean; id: number };
   [PurposeStrings.treatment_specific_info]: { checked: boolean; id: number };
   [PurposeStrings.risk_detection_recurrence]: { checked: boolean; id: number };
   [PurposeStrings.healthcare_management]: { checked: boolean; id: number };
   [PurposeStrings.symptom_tracking]: { checked: boolean; id: number };
   [PurposeStrings.cancer_in_youth]: { checked: boolean; id: number };
   [PurposeStrings.community_social_support]: { checked: boolean; id: number };
   [PurposeStrings.spirituality]: { checked: boolean; id: number };
   [PurposeStrings.advocacy_fundraising]: { checked: boolean; id: number };
}

export enum CancerStrings {
   general = 'General',
   lung = 'Lung',
   breast = 'Breast',
   prostate = 'Prostate',
   colon_rectum = 'Colon/rectum',
   ovarian = 'Ovarian',
   cervical = 'Cervical',
   cervix = 'Cervix',
   kidney = 'Kidney',
   pancreatic = 'Pancreatic',
   liver = 'Liver',
   leukemia = 'Leukemia',
   non_hodgkin_lymphoma = 'Non-Hodgkin lymphoma',
}

export enum PlatformStrings {
   web = 'Web',
   ios = 'iOS',
   android = 'Android',
}

export enum DeveloperTypeStrings {
   commercial = 'Commercial',
   not_for_profit = 'Not-For-Profit',
   academic = 'Academic',
   hospital_based = 'Hospital-Based',
}

export interface Platform {
   [PlatformStrings.android]: { checked: boolean; id: number };
   [PlatformStrings.ios]: { checked: boolean; id: number };
   [PlatformStrings.web]: { checked: boolean; id: number };
}

export interface Filters {
   [FilterKeys.purpose]: Purpose;
   [FilterKeys.cancer_type]: CancerType;
   [FilterKeys.evidence_based]: boolean | undefined;
   [FilterKeys.platform]: Platform;
   [FilterKeys.cost]: boolean | undefined;
   [FilterKeys.privacy_policy_url]: boolean;
   [FilterKeys.developer_type]: DeveloperType;
   query: string;
   filterResults: Resource[];
}

export interface DeveloperType {
   [DeveloperTypeStrings.academic]: { checked: boolean };
   [DeveloperTypeStrings.commercial]: { checked: boolean };
   [DeveloperTypeStrings.hospital_based]: { checked: boolean };
   [DeveloperTypeStrings.not_for_profit]: { checked: boolean };
}
