import { Action } from 'state/store';
import { deriveTruth, capitalize } from 'utility/ArrayUtils';

export enum Gender {
   male = 'male',
   female = 'female',
   transgender = 'transgender',
}

export enum Cancer {
   general = 'general',
   lung = 'lung',
   breast = 'breast',
   prostate = 'prostate',
   colon_rectum = 'colon_rectum',
   ovarian = 'ovarian',
   cervical = 'cervical',
   cervix = 'cervix',
   kidney = 'kidney',
   pancreatic = 'pancreatic',
   liver = 'liver',
   leukemia = 'leukemia',
   non_hodgkin_lymphoma = 'non_hodgkin_lymphoma',
   other = 'other',
}

export enum TreatmentStatus {
   not_started = 'not_started',
   ongoing = 'ongoing',
   complete = 'complete',
   not_planned = 'not_planned',
}

enum ResourceTarget {
   myself = 'Myself',
   someone_else = 'Someone else',
}

export enum RequestedResources {
   general_info = 'General cancer education/information',
   treatment_specific = 'Treatment-specific information',
   cancer_risk_detection_recurrence = 'Cancer risk, detection, and recurrence',
   healthcare_management = 'Healthcare management (e.g., tracking appointments, communicating with healthcare providers, etc.)',
   symptom_tracking_management_resources = 'Symptom tracking/management',
   cancer_in_youth = 'Children/Pediatric/Young Adult',
   community_social_support = 'Community/social support',
   emotional_spiritual_support = 'Spirituality/meaning in life',
   advocacy_fundraising_resources = 'Advocacy/fundraising',
   other = 'other',
}

export interface CancerType {
   [Cancer.general]?: { checked: boolean; id: number };
   [Cancer.lung]: { checked: boolean; id: number };
   [Cancer.breast]: { checked: boolean; id: number };
   [Cancer.prostate]: { checked: boolean; id: number };
   [Cancer.colon_rectum]: { checked: boolean; id: number };
   [Cancer.ovarian]: { checked: boolean; id: number };
   [Cancer.cervical]: { checked: boolean; id: number };
   [Cancer.cervix]: { checked: boolean; id: number };
   [Cancer.kidney]: { checked: boolean; id: number };
   [Cancer.pancreatic]: { checked: boolean; id: number };
   [Cancer.liver]: { checked: boolean; id: number };
   [Cancer.leukemia]: { checked: boolean; id: number };
   [Cancer.non_hodgkin_lymphoma]: { checked: boolean; id: number };
   [Cancer.other]?: { checked: boolean; value: string | null };
}

export type RequestedResourcesType = {
   [RequestedResources.general_info]: { checked: boolean; id: number };
   [RequestedResources.treatment_specific]: { checked: boolean; id: number };
   [RequestedResources.cancer_risk_detection_recurrence]: { checked: boolean; id: number };
   [RequestedResources.healthcare_management]: { checked: boolean; id: number };
   [RequestedResources.symptom_tracking_management_resources]: { checked: boolean; id: number };
   [RequestedResources.cancer_in_youth]: { checked: boolean; id: number };
   [RequestedResources.community_social_support]: { checked: boolean; id: number };
   [RequestedResources.emotional_spiritual_support]: { checked: boolean; id: number };
   [RequestedResources.advocacy_fundraising_resources]: { checked: boolean; id: number };
   [RequestedResources.other]: { checked: boolean; value: string | null };
};

export type TreatmentStatusType = {
   not_started: boolean;
   ongoing: boolean;
   complete: boolean;
   not_planned: boolean;
};
export interface Onboarding {
   age: number | null;
   gender: { male: boolean; female: boolean; transgender: boolean };
   diagnosedWithCancer: boolean | null;
   personal: boolean | null;
   caregiver: boolean | null;
   diagnosis: CancerType;
   treatmentStatus: { not_started: boolean; ongoing: boolean; complete: boolean; not_planned: boolean };
   focusedDiagnosis: CancerType;
   requestedResources: RequestedResourcesType;
}

const initialGenderState = { male: false, female: false, transgender: false };

const initialDiagnosisState: CancerType = {
   general: { checked: false, id: 1 },
   lung: { checked: false, id: 2 },
   breast: { checked: false, id: 3 },
   prostate: { checked: false, id: 4 },
   colon_rectum: { checked: false, id: 5 },
   ovarian: { checked: false, id: 6 },
   cervical: { checked: false, id: 7 },
   cervix: { checked: false, id: 8 },
   kidney: { checked: false, id: 9 },
   pancreatic: { checked: false, id: 10 },
   liver: { checked: false, id: 11 },
   leukemia: { checked: false, id: 12 },
   non_hodgkin_lymphoma: { checked: false, id: 13 },
   other: { checked: false, value: null },
};
const initialTreatmentStatusState = { not_started: false, not_planned: false, complete: false, ongoing: false };

const initialRequestedResourceState = {
   [RequestedResources.general_info]: { checked: false, id: 1 },
   [RequestedResources.treatment_specific]: { checked: false, id: 2 },
   [RequestedResources.cancer_risk_detection_recurrence]: { checked: false, id: 3 },
   [RequestedResources.healthcare_management]: { checked: false, id: 4 },
   [RequestedResources.symptom_tracking_management_resources]: { checked: false, id: 5 },
   [RequestedResources.cancer_in_youth]: { checked: false, id: 6 },
   [RequestedResources.community_social_support]: { checked: false, id: 7 },
   [RequestedResources.emotional_spiritual_support]: { checked: false, id: 8 },
   [RequestedResources.advocacy_fundraising_resources]: { checked: false, id: 9 },
   [RequestedResources.other]: { checked: false, value: null },
};

export const initialUserMeta: Onboarding = {
   age: null,
   caregiver: null,
   gender: initialGenderState,
   diagnosedWithCancer: null,
   personal: null,
   diagnosis: initialDiagnosisState,
   treatmentStatus: initialTreatmentStatusState,
   focusedDiagnosis: initialDiagnosisState,
   requestedResources: initialRequestedResourceState,
};

export type AnswerChoice = { label: string; checked: boolean; resolver: (value?: string) => Action };

export type Pages = { [key: string]: boolean };

const TreatmentStatusKey = {
   [TreatmentStatus.complete]: 'My treatment is complete',
   [TreatmentStatus.not_started]: 'I have not started treatment but it is planned',
   [TreatmentStatus.not_planned]: 'No treatment is planned',
   [TreatmentStatus.ongoing]: 'My treatment is ongoing',
};

type TreatmentStageStrings =
   | 'My treatment is complete'
   | 'I have not started treatment but it is planned'
   | 'No treatment is planned'
   | 'My treatment is ongoing'
   | null;

interface OnboardingFormItems {
   age: number;
   gender: 'Male' | 'Female' | 'Transgender' | null;
   diagnosedWithCancer: boolean;
   resourcesAreFor: ResourceTarget;
   caregiver: boolean | null;
   treatmentStage: TreatmentStageStrings;
   cancerSelections: number[];
   otherCancerSelection: string | null;
   resourceSelections: number[];
   otherResourceSelection: string | null;
}
//todo: address any typing
export const formatOnboardingData = (userMeta: Onboarding | any) => {
   const { diagnosis, focusedDiagnosis, requestedResources, treatmentStatus, gender } = userMeta;
   const checkBoxData = { props: { target: 'checked', desired: 'id' } };
   const cancerSelections: number[] = deriveTruth({ obj: diagnosis, ...checkBoxData });
   // const focusedDiagnoses: number[] = deriveTruth({ obj: focusedDiagnosis, ...checkBoxData })
   const resourceSelections: number[] = deriveTruth({ obj: requestedResources, ...checkBoxData });
   let treatmentStage: TreatmentStageStrings = null;
   let selectedGender: 'Male' | 'Female' | 'Transgender' | null = null;

   for (const stage in treatmentStatus) {
      if (Boolean(treatmentStatus[stage as keyof TreatmentStatus])) {
         //@ts-ignore
         treatmentStage = TreatmentStatusKey[stage];
      }
   }

   for (const type in gender) {
      if (Boolean(gender[type])) {
         //@ts-ignore
         selectedGender = capitalize(Gender[type]);
      }
   }
   let otherCancerType = '';
   if (diagnosis.other.checked) {
      otherCancerType = diagnosis.other.value;
   }
   if (focusedDiagnosis.other.checked) {
      otherCancerType = focusedDiagnosis.other.value;
   }

   let otherResourceType = '';
   if (requestedResources.other.checked) {
      otherResourceType = requestedResources.other.value;
   }
   const onboardingFormData: OnboardingFormItems = {
      age: userMeta.age,
      gender: selectedGender,
      diagnosedWithCancer: userMeta.diagnosedWithCancer,
      resourcesAreFor: userMeta.personal ? ResourceTarget.myself : ResourceTarget.someone_else,
      caregiver: userMeta.caregiver,
      treatmentStage,
      //to be determined how this will work
      cancerSelections,
      otherCancerSelection: otherCancerType,
      resourceSelections,
      otherResourceSelection: otherResourceType,
   };
   return onboardingFormData;
};
