export const TOKEN: string = 'ACCESS_TOKEN';
/**
 * USER
 */
const REGISTER_USER: string = 'REGISTER_USER';
const LOGIN_USER: string = 'LOGIN_USER';
const LOGOUT_USER: string = 'LOGOUT_USER';
const FETCH_FAVORITES: string = 'FETCH_FAVORITES';
const TOGGLE_FAVORITE: string = 'TOGGLE_FAVORITE';
const FETCH_USER_PROFILE: string = 'FETCH_USER_PROFILE';
const UPDATE_USER_PROFILE: string = 'UPDATE_USER_PROFILE';
const USER_ERROR: string = 'USER_ERROR';

export const UserEvents = {
   REGISTER_USER,
   LOGIN_USER,
   LOGOUT_USER,
   FETCH_FAVORITES,
   TOGGLE_FAVORITE,
   FETCH_USER_PROFILE,
   UPDATE_USER_PROFILE,
   USER_ERROR,
};

/**
 * USER META
 */
const SET_AGE = 'SET_AGE',
   SET_GENDER = 'SET_GENDER',
   SET_PREVIOUS_DIAGNOSIS = 'SET_PREVIOUS_DIAGNOSIS',
   SET_PERSONAL = 'SET_PERSONAL',
   SET_CAREGIVER = 'SET_CAREGIVER',
   SET_DIAGNOSIS = 'SET_DIAGNOSIS',
   SET_DIAGNOSIS_OTHER = 'SET_DIAGNOSIS_OTHER',
   SET_TREATMENT_STATUS = 'SET_TREATMENT_STATUS',
   SET_FOCUSED_DIAGNOSIS = 'SET_FOCUSED_DIAGNOSIS',
   SET_FOCUSED_DIAGNOSIS_OTHER = 'SET_FOCUSED_DIAGNOSIS_OTHER',
   SET_REQUESTED_RESOURCES = 'SET_REQUESTED_RESOURCES',
   SET_REQUESTED_RESOURCES_OTHER = 'SET_REQUESTED_RESOURCES_OTHER';

export const UserMetaEvents = {
   SET_AGE,
   SET_GENDER,
   SET_PREVIOUS_DIAGNOSIS,
   SET_PERSONAL,
   SET_CAREGIVER,
   SET_DIAGNOSIS,
   SET_DIAGNOSIS_OTHER,
   SET_TREATMENT_STATUS,
   SET_FOCUSED_DIAGNOSIS,
   SET_FOCUSED_DIAGNOSIS_OTHER,
   SET_REQUESTED_RESOURCES,
   SET_REQUESTED_RESOURCES_OTHER,
};

/**
 * RESOURCES
 */
const FETCH_RECOMMENDED: string = 'FETCH_RECOMMENDED';
const FETCH_CATEGORIES: string = 'FETCH_CATEGORIES';
const FETCH_CATEGORY: string = 'FETCH_CATEGORY';
//category being viewed by user via /category/:name
const SET_SELECTED_CATEGORY: string = 'SET_SELECTED_CATEGORY';
const SET_SELECTED_RESOURCE: string = 'SET_SELECTED_RESOURCE';
const SELECTED_CATEGORY: string = 'SELECTED_CATEGORY';
const SET_SEARCH_RESULTS: string = 'SET_SEARCH_RESULTS';

//not used currently
const RESOURCE_ERROR: string = 'RESOURCE_ERROR';

export const ResourceEvents = {
   FETCH_RECOMMENDED,
   FETCH_CATEGORIES,
   FETCH_CATEGORY,
   SET_SELECTED_RESOURCE,
   SET_SELECTED_CATEGORY,
   SELECTED_CATEGORY,
   SET_SEARCH_RESULTS,
   RESOURCE_ERROR,
};

/**
 * FILTERS
 */
const TOGGLE_FILTER: string = 'TOGGLE_FILTER';
const CLEAR_FILTERS: string = 'CLEAR_FILTERS';
const BUILD_FILTER_QUERY: string = 'BUILD_FILTER_QUERY';
const APPLY_FILTER_QUERY: string = 'APPLY_FILTER_QUERY';
const SET_FILTER_RESULTS: string = 'SET_FILTER_RESULTS';

export const FilterEvents = {
   TOGGLE_FILTER,
   CLEAR_FILTERS,
   BUILD_FILTER_QUERY,
   APPLY_FILTER_QUERY,
   SET_FILTER_RESULTS,
};

/**
 * SIDEBAR
 */
export const OPEN_SIDEBAR: string = 'OPEN';
export const CLOSE_SIDEBAR: string = 'CLOSE';
