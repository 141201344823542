import React, { useState } from 'react';
import styled from 'styled-components';
import { ButtonClickEvent } from 'types/events';

interface Props {
   isButton?: boolean;
   color?: string;
   onClick?: any;
   favorite: boolean;
}

const Heart: React.FC<Props> = ({ isButton, color = '#D7425A', onClick, favorite }): JSX.Element => {
   const [isFavorite, setIsFavorite] = useState(favorite);

   const handleClick = () => {
      setIsFavorite(!isFavorite);
      onClick();
   };

   const FilledHeartSVG: React.FC<{ color: string }> = ({ color }): JSX.Element => (
      <svg color={color} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={color} viewBox="0 0 33 33">
         <path d="M0 10 C0 6, 3 2, 8 2 C12 2, 15 5, 16 6 C17 5, 20 2, 24 2 C30 2, 32 6, 32 10 C32 18, 18 29, 16 30 C14 29, 0 18, 0 10" />
      </svg>
   );

   const OutlineHeartSVG: React.FC<{ color: string }> = ({ color }): JSX.Element => (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 24" width="26" height="26" fill={color}>
         <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z " />
      </svg>
   );

   return isButton ? (
      <Button onClick={handleClick}>{isFavorite ? <FilledHeartSVG color={color} /> : <OutlineHeartSVG color={color} />}</Button>
   ) : (
      <OutlineHeartSVG color={color} />
   );
};

export default Heart;

const Button = styled.button.attrs({ type: 'button' })`
   background-color: transparent;
   border: none;
   height: 28px;
   width: 28px;
   margin: 0;
   &:hover {
      cursor: pointer;
   }
`;
