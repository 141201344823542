import React, { useState, Fragment, useContext, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Store } from 'state/store';
import PageHeading from 'components/interface/Heading/PageHeading';
import { Resource } from 'network/EstarApi/Resources';
import { ViewContainer, Alerts, CardLayout } from 'components/SharedStyledComponents';
import { fetchFavorites } from 'state/actions/UserActions';
import Loading from 'Elements/Loading';
import Flex from 'Elements/Flex';
import ResourceCard from 'components/interface/Resource/ResourceCard/ResourceCard';
import { FilterEvents } from 'state/constants';

const Favorites: React.FC<RouteComponentProps> = (): JSX.Element => {
   const {
      state: { userState, filterState },
      dispatch,
   } = useContext(Store);
   const [loading, setLoading] = useState<boolean>(false);
   const [contentToRender, setContentToRender] = useState<Resource[]>(userState.favorites);

   const fetchUserFavorites = async (): Promise<void> => {
      setLoading(true);
      await fetchFavorites(dispatch);
      setLoading(false);
   };

   useEffect(() => {
      Boolean(filterState.filterResults) && setContentToRender(filterState.filterResults);
   }, [filterState.filterResults]);

   useEffect(() => {
      dispatch({ type: FilterEvents.CLEAR_FILTERS });
      fetchUserFavorites();
   }, []);
   useEffect(() => {
      setContentToRender(userState.favorites);
   }, [userState.favorites]);

   return (
      <ViewContainer>
         {loading ? (
            <Loading />
         ) : (
            <Fragment>
               <PageHeading title="Favorites" resultCount={contentToRender.length || 0} />
               {!contentToRender.length && !loading ? (
                  <Alerts.InfoMessage>
                     <p>You currently don't have any favorites to display!</p>
                  </Alerts.InfoMessage>
               ) : (
                  <ColumnsWrapper>
                     {contentToRender.map(
                        (resource: Resource): JSX.Element => (
                           <CardColumnContainer key={resource.id}>
                              <CardColumn>
                                 <ResourceCard {...resource} />
                              </CardColumn>
                           </CardColumnContainer>
                        )
                     )}
                  </ColumnsWrapper>
               )}
            </Fragment>
         )}
      </ViewContainer>
   );
};

export default Favorites;

const { CardColumnContainer, CardColumn, ColumnsWrapper } = CardLayout;
