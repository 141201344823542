import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { displayPosition, justifyContent } from 'types/cssAttributes';

interface Props {
   flexDirection: 'row' | 'column';
   justifyContent: justifyContent;
   alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
   alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch' | 'auto';
   margin?: string | number;
   flexWrap?: 'wrap' | 'no-wrap';
   padding?: string | number;
   flex?: number;
   width?: string;
   height?: string;
   position?: displayPosition;
   children: ReactNode;
   backgroundColor?: string;
}

const Flex: React.FC<Props> = ({
   flexDirection,
   justifyContent,
   alignItems = 'stretch',
   alignSelf = 'auto',
   margin = '0 auto',
   flexWrap = 'wrap',
   padding = 0,
   flex = 1,
   width = '100%',
   height = 'auto',
   position = 'static',
   children,
   backgroundColor = 'inherit',
}): JSX.Element => (
   <FlexContainer
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignSelf={alignSelf}
      margin={margin}
      padding={padding}
      flexWrap={flexWrap}
      position={position}
      flex={flex}
      width={width}
      height={height}
      backgroundColor={backgroundColor}>
      {children}
   </FlexContainer>
);

export default Flex;

const FlexContainer = styled.div`
   display: flex;
   flex-direction: ${({ flexDirection }: Props) => flexDirection};
   justify-content: ${({ justifyContent }: Props) => justifyContent};
   margin: ${({ margin }: Props) => margin};
   flex-wrap: ${({ flexWrap }: Props) => flexWrap};
   flex: ${({ flex }: Props) => flex};
   width: ${({ width }: Props) => width};
   height: ${({ height }: Props) => height};
   padding: ${({ padding }: Props) => padding};
   position: ${({ position }: Props) => position};
   background-color: ${({ backgroundColor }: Props) => backgroundColor};
   align-items: ${({ alignItems }: Props) => alignItems};
   align-self: ${({ alignSelf }: Props) => alignSelf};
`;
