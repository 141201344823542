// Wraps passed entity into an array if it is not one.
// Passing undefined is considered unwanted and will return
// an empty array instead.
export const arrayify = (entity: any): any[] => {
   if (entity === undefined || !entity.length) {
      return [];
   }

   return Array.isArray(entity) ? entity : [entity];
};

//will take in an object with boolean values and ensure all values are set to false
export const reset = (obj: { [key: string]: boolean }): { [key: string]: boolean } => {
   for (const key in obj) {
      obj[key] = false;
   }
   return obj;
};

type deriveTruthArgs = { obj: any; props: { target: string; desired: string } };

//designed to gather checkbox data that have been checked
export const deriveTruth = ({ obj, props }: deriveTruthArgs): any => {
   let trueResults: any[] | any = [];
   for (const key in obj) {
      if (obj[key][props.target]) {
         obj[key][props.desired] && trueResults.push(obj[key][props.desired]);
      }
   }
   return trueResults;
};

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);
