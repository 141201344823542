import React, { Component } from 'react';
import Chevron from 'components/interface/icons/Chevron';
import { XIcon } from 'components/views/Modal';
import Magnifier from './Magnifier';
import Bookmark from './Bookmark';
import { ButtonClickEvent } from 'types/events';
import { displayPosition } from 'types/cssAttributes';
import Lock from './Lock';
import User from './User';
import Heart from './Heart';
import Filter from './Filter';
import Sort from './Sort';
import ThumbsUp from './ThumbsUp';
import Grid from './Grid';
import Star from './Star';

interface Props {
   color?: string;
   type?: IconType;
   isButton?: boolean;
   onClick?: ButtonClickEvent;
   position?: displayPosition;
   height?: string;
   width?: string;
   isHalf: boolean | null;
   isFavorite: boolean;
}

export enum IconType {
   chevronDown = 'chevron-down',
   chevronUp = 'chevron-up',
   close = 'close',
   magnifier = 'magnifier',
   bookmark = 'bookmark',
   lock = 'lock',
   user = 'user',
   heart = 'heart',
   filter = 'filter',
   sort = 'sort',
   thumbs_up = 'thumbs_up',
   grid = 'grid',
   star = 'star',
}
// component is class-based in order to use switch statement
class Icon extends Component<Props> {
   static defaultProps: Props = {
      color: '#D2D0C7',
      position: 'static',
      isHalf: false,
      isFavorite: false,
   };
   render() {
      const { type, color } = this.props;
      switch (type) {
         case IconType.chevronDown:
            return <Chevron down={true} />;
         case IconType.chevronUp:
            return <Chevron down={false} />;
         case IconType.close:
            return <XIcon color={color} />;
         case IconType.magnifier:
            return this.props.isButton && this.props.onClick ? (
               <Magnifier isButton={this.props.isButton} onClick={this.props.onClick} color={this.props.color} />
            ) : (
               <Magnifier />
            );
         case IconType.bookmark:
            return <Bookmark position={this.props.position} />;
         case IconType.lock:
            return <Lock color={this.props.color} />;
         case IconType.user:
            return <User color={this.props.color} height={this.props.height} width={this.props.width} />;
         case IconType.heart:
            return this.props.isButton && this.props.color ? (
               <Heart isButton={this.props.isButton} color={this.props.color} onClick={this.props.onClick} favorite={this.props.isFavorite} />
            ) : (
               <Heart color={this.props.color} favorite={this.props.isFavorite} />
            );
         case IconType.filter:
            return <Filter color={this.props.color} />;
         case IconType.sort:
            return <Sort />;
         case IconType.thumbs_up:
            return <ThumbsUp color={this.props.color || ''} />;
         case IconType.grid:
            return <Grid color={this.props.color || ''} />;
         case IconType.star:
            return <Star isHalf={this.props.isHalf} />;
      }
   }
}
export default Icon;
