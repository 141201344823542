import React, { Fragment } from 'react';
import Icon, { IconType } from 'components/interface/icons/IconProvider';
import uuid from 'uuid';

const Rating: React.FC<{ rating: number | null }> = ({ rating }): JSX.Element => {
   let stars: JSX.Element[] = [];
   if (rating) {
      //if the rating was 3.5, this will loop up to 3
      for (let i = 1; i <= rating; i++) {
         stars.push(<Icon type={IconType.star} isHalf={false} key={uuid.v4()} />);
      }
      //if it's determined that the rating is not a whole number, i.e. a decimal, add a half star
      if (rating % 1 !== 0) {
         //ensure that the rating is properly "rounded" by checking if its decimal is greater than or equal to .5
         stars.push(
            Number(String(rating).split('.')[2]) >= 0.5 ? (
               <Icon type={IconType.star} isHalf={true} key={uuid.v4()} />
            ) : (
               <Icon type={IconType.star} isHalf={null} key={uuid.v4()} />
            )
         );
      }
      //if the total stars generated does not equal 5, add empty star placeholders
      if (stars.length !== 5) {
         for (let i = stars.length; i < 5; i++) {
            stars.push(<Icon type={IconType.star} isHalf={null} key={uuid.v4()} />);
         }
      }
   }
   return <Fragment>{stars}</Fragment>;
};

export default Rating;
