/* eslint-disable react/jsx-indent-props */
import React from 'react';
import styled from 'styled-components';

interface Props {
   toggle?: () => void;
   down: boolean;
}

const Chevron: React.FC<Props> = ({ toggle = () => {}, down }): JSX.Element => (
   <Button onClick={toggle}>
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 16 16"
         width="24"
         height="24"
         fill="none"
         stroke="currentcolor"
         strokeWidth="3"
         style={{ display: 'inline-block', verticalAlign: 'middle', overflow: 'visible' }}>
         <path
            d={
               down
                  ? 'M1.0606601717798212 5 L8 11.939339828220179 L14.939339828220179 5'
                  : 'M1.0606601717798212 11 L8 4.060660171779821 L14.939339828220179 11'
            }
         />
      </svg>
   </Button>
);

export default Chevron;

const Button = styled.button.attrs({ type: 'button' })`
   background: transparent;
   border: none;
   &:hover {
      cursor: pointer;
   }
`;
