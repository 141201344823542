import React, { useContext, useState, MouseEvent, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Store } from 'state/store';
import Icon, { IconType } from './icons/IconProvider';
import useToggle from 'hooks/useToggle';
import { navigate, Link } from '@reach/router';
import { logoutUser } from 'state/actions/UserActions';

const NavDropdown: React.FC = (): JSX.Element => {
   const {
      state: {
         userState: { currentUser },
      },
      dispatch,
   } = useContext(Store);
   const [open, setOpen] = useState(false);
   // DropdownContent visibility will be determined by css:hover on desktop and by toggling of open state on mobile/tablet

   const toggleDropdown = useCallback(() => {
      setOpen(!open);
   }, [open, setOpen]);

   useEffect(() => {
      // This useEffect allows the dropdown to be closed
      // when the user clicks anywhere outside of the dropdown itself
      const eventHandler = () => {
         setOpen(false);
         document.removeEventListener('click', eventHandler, false);
      };

      if (open) {
         document.addEventListener('click', eventHandler, false);
      }
   }, [open]);

   return (
      <Dropdown>
         <Button onClick={toggleDropdown} id="user-icon">
            <Icon type={IconType.user} height="25" width="25" /> <Username>{currentUser.username || 'Username'}</Username>
         </Button>
         <DropdownContent visible={open} id="nav-dropdown-content">
            <ContentItem>
               <ContentButton>
                  <ContentLink to="/profile">Profile</ContentLink>
               </ContentButton>
            </ContentItem>
            <ContentItem>
               <ContentButton
                  onClick={() => {
                     logoutUser(dispatch);
                     navigate('/');
                  }}>
                  Logout
               </ContentButton>
            </ContentItem>
         </DropdownContent>
      </Dropdown>
   );
};

export default NavDropdown;

const DropdownContent = styled.div`
   display: none;
   position: absolute;
   right: 0;
   background-color: #f9f9f9;
   min-width: 160px;
   border: 1px solid ${({ theme }) => theme.secondaryGray};
   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0, 2);
   z-index: 100;

   @media only screen and (max-width: 600px) {
      display: ${({ visible }: { visible: boolean }) => (visible ? 'block' : 'none')};
      left: 0;
      width: 40%;
   }
`;

const ContentItem = styled.div`
   height: 40px;
   padding: 10px;
   border-top: 1px solid ${({ theme }) => theme.secondaryGray};
   border-bottom: 1px solid ${({ theme }) => theme.secondaryGray};
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
`;

const ContentButton = styled.button.attrs({ type: 'button' })`
   border: none;
   background-color: transparent;
   font-size: 1em;
   cursor: pointer;

   &:hover {
      color: ${({ theme }) => theme.secondaryBlue};
   }
`;

const ContentLink = styled(Link)`
   text-decoration: none;
   color: inherit;
`;

const Button = styled.button.attrs({ type: 'button' })`
   background: transparent;
   border: none;
   color: ${({ theme }) => theme.primaryWhite};
   font-size: 1.2em;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-content: center;
   &:hover {
      cursor: pointer;
   }
`;

const Username = styled.span`
   margin-left: 12px;
   font-weight: bold;
   display: none;

   @media (min-width: 1290px) {
      display: block;
   }
`;

const Dropdown = styled.div`
   position: absolute;
   background: transparent;
   width: auto;
   text-align: center;
   left: 0;
   margin-left: 15px;
   &:hover ${DropdownContent} {
      display: block;
   }

   @media only screen and (min-width: 600px) {
      right: 10px;
      left: initial;
   }
`;
