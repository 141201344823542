import React, { Fragment, useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Resource } from 'network/EstarApi/Resources';
import { ViewContainer, Alerts, CardLayout } from 'components/SharedStyledComponents';
import PageHeading from 'components/interface/Heading/PageHeading';
import Loading from 'Elements/Loading';
import ResourceCard from 'components/interface/Resource/ResourceCard/ResourceCard';
import { fetchSearchResults } from 'state/actions/ResourceActions';
import { Store } from 'state/store';
import { FilterEvents } from 'state/constants';

interface Props extends RouteComponentProps {
   query?: string;
}

const SearchResults: React.FC<Props> = ({ query }): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const {
      state: { resourceState, filterState },
      dispatch,
   } = useContext(Store);
   const [contentToRender, setContentToRender] = useState<Resource[]>(resourceState.search.results);

   const fetchUserSearchResults = async () => {
      setLoading(true);
      await fetchSearchResults(dispatch, query as string);
      setLoading(false);
   };

   useEffect(() => {
      dispatch({ type: FilterEvents.CLEAR_FILTERS });
   }, []);

   useEffect(() => {
      Boolean(filterState.filterResults.length) && setContentToRender(filterState.filterResults);
   }, [filterState.filterResults]);

   useEffect(() => {
      fetchUserSearchResults();
   }, [query]);

   useEffect(() => {
      setContentToRender(resourceState.search.results);
   }, [resourceState.search.results]);

   return (
      <ViewContainer>
         {loading ? (
            <Loading />
         ) : (
            <Fragment>
               <PageHeading
                  title={resourceState.search.query ? `"${resourceState.search.query.replace('+', ' ')}"` : ''}
                  resultCount={contentToRender.length || 0}
               />
               {!contentToRender.length && !loading ? (
                  <Alerts.InfoMessage>
                     <p>There currently aren't any resources that match your search!</p>
                  </Alerts.InfoMessage>
               ) : (
                  <ColumnsWrapper>
                     {contentToRender.map(
                        (resource: Resource): JSX.Element => (
                           <CardColumnContainer key={resource.id}>
                              <CardColumn>
                                 <ResourceCard {...resource} />
                              </CardColumn>
                           </CardColumnContainer>
                        )
                     )}
                  </ColumnsWrapper>
               )}
            </Fragment>
         )}
      </ViewContainer>
   );
};

const { CardColumnContainer, CardColumn, ColumnsWrapper } = CardLayout;

export default SearchResults;
