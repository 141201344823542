import { Action, Reducer } from 'state/store';
import { ResourceEvents as actions } from 'state/constants';
import { Resource, Tag } from 'network/EstarApi/Resources';

interface ResourceState {
   resources: Resource[];
   categories: Tag[];
   search: { query: string; results: Resource[] };
   selectedResource: Resource | {};
   selectedCategory: { displayName: string; resources: Resource[] };
   error: string | null;
}

const initialState: ResourceState = {
   resources: [],
   categories: [],
   search: { query: '', results: [] },
   selectedResource: {},
   selectedCategory: { displayName: localStorage.getItem(actions.SELECTED_CATEGORY) || '', resources: [] },
   error: null,
};

const reducer: Reducer = (state = initialState, action: Action) => {
   const { type, payload } = action;
   switch (type) {
      case actions.FETCH_RECOMMENDED:
         return { ...state, resources: payload as Resource[] };
      case actions.FETCH_CATEGORIES:
         return { ...state, categories: payload as Tag[] };
      case actions.FETCH_CATEGORY:
         state.selectedCategory.resources = payload as Resource[];
         return state;
      case actions.SET_SELECTED_RESOURCE:
         return { ...state, selectedResource: payload as Resource };
      case actions.SET_SEARCH_RESULTS:
         state.search.query = payload.search as string;
         state.search.results = payload.results as Resource[];
         return state;
      case actions.SET_SELECTED_CATEGORY:
         state.selectedCategory.displayName = payload as string;
         localStorage.setItem(actions.SELECTED_CATEGORY, payload);
         return state;
      case actions.RESOURCE_ERROR:
         return { ...state, error: payload };
      default:
         return state as ResourceState;
   }
};

export default { initialState, reducer };
