import { Action } from 'state/store';
import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from 'state/constants';

export default (isOpen: boolean = false, { type, payload }: Action) => {
   switch (type) {
      case OPEN_SIDEBAR:
         isOpen = payload as boolean;
         return isOpen;
      case CLOSE_SIDEBAR:
         isOpen = payload as boolean;
         return isOpen;
      default:
         return isOpen;
   }
};
