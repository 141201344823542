import React from 'react';

const ThumbsUp: React.FC<{ color: string }> = ({ color }): JSX.Element => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      width="24px"
      height="24px"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24">
      <path
         d="M5 9v12H1V9h4zm4 12a2 2 0 0 1-2-2V9c0-.552.224-1.052.586-1.414L14.172 1l1.06 1.055c.272.271.44.646.44 1.06l-.033.316L14.685 8H21a2 2 0 0 1 2 2v2c0 .257-.053.502-.141.727l-3.017 7.053A2 2 0 0 1 18 21H9zm0-2.002h9.026l2.973-7.006V9.998h-8.784l1.13-5.317-4.346 4.345v9.972z"
         fill={color}
      />
   </svg>
);

export default ThumbsUp;
