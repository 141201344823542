import apiBaseUrl from 'config';

export const prependApiUrl = (url: string): string => {
   const baseUrl: string = !apiBaseUrl.startsWith('http') ? `https://${apiBaseUrl}` : apiBaseUrl;
   return `${baseUrl}/${url}`;
};

type headers = Object;
type cache = 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
type method = 'GET' | 'PUT' | 'POST' | 'DELETE';
type mode = 'same-origin' | 'no-cors' | 'cors' | 'navigate';
type redirect = 'follow' | 'manual' | 'error';
type referrer = 'client' | 'no-referrer';

interface GenericRequest {
   path?: string;
   params?: Object;
   body?: Object;
   url?: string;
   token?: string | null | undefined;
   headers?: headers;
   mode?: mode;
   method?: method;
   cache?: cache;
   redirect?: redirect;
   referrer?: referrer;
}

interface FetchConfig {
   headers: any;
   cache: cache;
   method: method;
   mode: mode;
   redirect: redirect;
   referrer: referrer;
   body?: string; //expects it to be result of JSON.stringify()
}

export default async function FetchWrapper(request: GenericRequest) {
   const {
      path,
      params,
      body,
      method = 'GET',
      url: baseUrl = apiBaseUrl,
      headers: suppliedHeaders = {},
      cache = 'default',
      mode = 'same-origin',
      token = null,
      redirect = 'follow',
      referrer = 'client',
   } = request;

   if (!path) {
      throw new Error(`'path' property not specified for fetch call`);
   }
   const url: string = buildRequestUrl(baseUrl, path, params);
   const fetchConfig: FetchConfig = {
      headers: buildRequestHeaders(suppliedHeaders, token),
      cache,
      method,
      mode,
      redirect,
      referrer,
   };

   if (method !== 'GET') {
      fetchConfig.body = JSON.stringify(body);
   }

   const response = await fetch(url, fetchConfig);
   if (response.ok) {
      return response;
   }
   throw response;
}
function buildRequestUrl(domain: string, path: string, params: any): string {
   // must be 'any' typed to add search property
   domain = !domain.startsWith('http') ? `https://${domain}` : domain;
   const url: URL | any = new URL(`${domain}${path}`);
   url.search = params ? new URLSearchParams(params) : url.search;
   return url.toString();
}

function buildRequestHeaders(additionalHeaders: any, token: string | null): any {
   const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
   return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'X-Requested-With': 'XMLHttpRequest',
      ...authHeaders,
      ...additionalHeaders,
   };
}
