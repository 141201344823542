import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

export const theme = {
   primaryBlue: '#1762A3',
   primaryBlueLighter: '#107FC9',
   primaryDarker: '#184682',
   secondaryBlue: '#1390DB',
   tertiaryBlue: '#d9eefa',
   primaryOrange: '#CC8141',
   secondaryOrange: '#b06c31',
   primaryGreen: '#8EB755',
   secondaryGreen: '#709141',
   tertiaryGreen: '#d7f7ab',
   primaryYellow: '#F6C71C',
   primaryGray: '#bdbbb3',
   secondaryGray: '#e3e0d5',
   tertiaryGray: '#8f8e89',
   primaryWhite: '#FFFFFF',
   primaryOffWhite: '#F1F1F5',
   primaryPrettyMuchWhite: '#F7F7F7',
   primaryRed: '#D7425A',
   secondaryRed: '#b82a41',
   almostBlack: 'hsl(0, 0%, 18%)',
   boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',
   boxShadowTop: '0 -12px 24px -12px rgba(0, 0, 0, 0.2)',
   boxShadowBottom: '0 12px 24px -12px rgba(0, 0, 0, 0.2)',
};

const ThemeWrapper: React.FC<{ children: any }> = ({ children }): JSX.Element => (
   <Fragment>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
      <GlobalStyle />
   </Fragment>
);

const GlobalStyle = createGlobalStyle`
   
   html {
      width: 100%;
      height: 100%;
      background: ${theme.primaryBlue};
      background: -moz-linear-gradient(top, ${theme.secondaryBlue} 0%, ${theme.primaryDarker} 100%); 
      background: -webkit-linear-gradient(top, ${theme.secondaryBlue} 0%, ${theme.primaryDarker} 100%);
      background: linear-gradient(to bottom, ${theme.secondaryBlue} 0%, ${theme.primaryDarker} 100%);
      box-sizing: border-box;
      font-size: 10px;
      overflow: auto;
      border: solid 1px transparent;
      font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif';
    
    
   }
   *, *:before, *:after {
      box-sizing: inherit;
   }
   body {
      height: 100%;
      width: 100%;
      padding: 0;
      margin: 0;
      font-size: 1.5rem;
      line-height: 2;
   }

   #root {
      height: 100%;
      width: 100%;
   }
   
   p {
   margin-top: 15px;
   font-size: 1em;
   line-height: 1.5;
   }
`;

export default ThemeWrapper;
