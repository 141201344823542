import React, { useState, useContext, useEffect } from 'react';
import { QuestionLayout } from 'components/SharedStyledComponents';
import { SetState } from 'types/events';
import { UserMetaEvents as actions } from 'state/constants';
import { Pages, RequestedResources, RequestedResourcesType } from 'state/user-meta';
import { Store } from 'state/store';
import CheckboxGroup from 'components/interface/Form/CheckboxGroup';
import useInputValue from 'hooks/useInputValue';

interface Props {
   requestedResources: RequestedResourcesType;
   setPagesComplete: SetState<Pages>;
}

const PageSix: React.FC<Props> = ({ requestedResources, setPagesComplete }): JSX.Element => {
   const { dispatch } = useContext(Store);
   //if at least one of the boxes have been checked or the "other" input has a value
   const [isComplete, setIsComplete] = useState<boolean>(false);
   const otherRequestedResources = useInputValue('');
   const determinePageCompleteness = () => {
      if (
         Object.values(requestedResources).some((resource: any): boolean => resource.checked === true) ||
         otherRequestedResources.value !== ''
      ) {
         setIsComplete(true);
      } else {
         setIsComplete(false);
      }
      setPagesComplete((prevPages: Pages) => ({ ...prevPages, six: isComplete }));
   };

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      determinePageCompleteness();
   }, [requestedResources, isComplete, otherRequestedResources.value]);

   return (
      <QuestionContainer>
         <CheckboxGroup
            question="What kind of resources are you searching for? Check all that apply."
            answerChoices={[
               {
                  label: RequestedResources.general_info,
                  checked: requestedResources[RequestedResources.general_info].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.general_info }),
               },
               {
                  label: RequestedResources.treatment_specific,
                  checked: requestedResources[RequestedResources.treatment_specific].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.treatment_specific }),
               },
               {
                  label: RequestedResources.cancer_risk_detection_recurrence,
                  checked: requestedResources[RequestedResources.cancer_risk_detection_recurrence].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.cancer_risk_detection_recurrence,
                     }),
               },
               {
                  label: RequestedResources.healthcare_management,
                  checked: requestedResources[RequestedResources.healthcare_management].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.healthcare_management }),
               },
               {
                  label: RequestedResources.symptom_tracking_management_resources,
                  checked: requestedResources[RequestedResources.symptom_tracking_management_resources].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.symptom_tracking_management_resources,
                     }),
               },
               {
                  label: RequestedResources.cancer_in_youth,
                  checked: requestedResources[RequestedResources.cancer_in_youth].checked,
                  resolver: () => dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: RequestedResources.cancer_in_youth }),
               },
               {
                  label: RequestedResources.community_social_support,
                  checked: requestedResources[RequestedResources.community_social_support].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.community_social_support,
                     }),
               },
               {
                  label: RequestedResources.emotional_spiritual_support,
                  checked: requestedResources[RequestedResources.emotional_spiritual_support].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.emotional_spiritual_support,
                     }),
               },
               {
                  label: RequestedResources.advocacy_fundraising_resources,
                  checked: requestedResources[RequestedResources.advocacy_fundraising_resources].checked,
                  resolver: () =>
                     dispatch({
                        type: actions.SET_REQUESTED_RESOURCES,
                        payload: RequestedResources.advocacy_fundraising_resources,
                     }),
               },
            ]}
         />
         <OtherInputContainer>
            <OtherTextInputLabel>Other (please specify)</OtherTextInputLabel>
            <OtherTextInput
               {...otherRequestedResources}
               onBlur={() =>
                  otherRequestedResources.value !== '' &&
                  dispatch({ type: actions.SET_REQUESTED_RESOURCES_OTHER, payload: otherRequestedResources.value })
               }
            />
         </OtherInputContainer>
      </QuestionContainer>
   );
};

export default PageSix;

const { QuestionContainer, OtherInputContainer, OtherTextInput, OtherTextInputLabel } = QuestionLayout;
