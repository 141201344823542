import React, { useContext, Fragment, useEffect } from 'react';
import { RouteComponentProps, Router, navigate } from '@reach/router';
import styled from 'styled-components';
import Filters from 'components/views/Filters';
import Onboarding from 'components/views/Onboarding/Onboarding';
import Favorites from 'components/views/Favorites';
import Header from 'components/interface/Header';
import Recommended from 'components/views/Recommended';
import Categories from 'components/views/Categories';
import Category from 'components/views/Category';
import Login from 'components/views/Login';
import Profile from 'components/views/Profile';
import Resource from 'components/views/Resource';
import NotFound from 'components/views/NotFound';
import AuthTokenManager from './network/AuthTokenManager';
import { Store } from './state/store';
import { fetchProfile, getUser } from './state/actions/UserActions';
import SearchResults from 'components/views/SearchResults';

const App: React.FC = (): JSX.Element => {
   return (
      <AppContainer>
         <Router>
            <Login path="/login" />
            <ProtectedRoutes path="/">
               <Onboarding path="/onboarding" />
               <HeaderWrappedRoutes default />
            </ProtectedRoutes>
         </Router>
      </AppContainer>
   );
};

const HeaderWrappedRoutes: React.FC<RouteComponentProps> = (): JSX.Element => (
   <Header>
      <Router>
         <Recommended path="/recommended" />
         <Favorites path="/favorites" />
         <Resource path="/resource/:id" />
         <SearchResults path="/search/:query" />
         <Categories path="/categories" />
         <Category path="/categories/:id" />
         <Filters path="/filters" />
         <Profile path="/profile" />
         <NotFound default />
      </Router>
   </Header>
);

const ProtectedRoutes: React.FC<any> = ({ children, location }): JSX.Element => {
   const accessToken = AuthTokenManager.getAccessToken();
   const { dispatch, state } = useContext(Store);
   const { loaded } = state.userState.currentUser;
   const handleRedirect = async () => {
      if (accessToken && loaded && location.pathname === '/') {
         await navigate('/recommended');
      }
   };
   useEffect(() => {
      const bootstrap = async () => {
         if (accessToken && !loaded) {
            await getUser(dispatch);
            fetchProfile(dispatch);
         }
      };
      bootstrap();
   }, [accessToken, loaded]);

   useEffect(() => {
      handleRedirect();
   });

   if (accessToken && !loaded) {
      return <Default />;
   }
   return loaded ? <Fragment>{children}</Fragment> : <Login />;
};

const Default = () => {
   return (
      <Header>
         <div />
      </Header>
   );
};

export default App;

const AppContainer = styled.section`
   margin: 0 auto;
   box-sizing: border-box;
   overflow: auto;
   height: 100%;
   width: 100%;
`;
