import React from 'react';
import styled from 'styled-components';
import Flex from 'Elements/Flex';
import { PlatformLink } from 'network/EstarApi/Resources';

const PlatformLinks: React.FC<{ platforms: PlatformLink[] }> = ({ platforms }): JSX.Element => (
   <Flex flexDirection="row" justifyContent="flex-start" margin="10px auto 0" alignItems="center">
      {platforms.map(
         ({ url, id, platform }: PlatformLink): JSX.Element => (
            <Link key={id} href={url}>
               {platform.name}
            </Link>
         )
      )}
   </Flex>
);

export default PlatformLinks;

const Link = styled.a.attrs(({ href }) => ({ href, target: '_blank' }))`
   text-decoration: none;
   color: ${({ theme }) => theme.primaryWhite};
   background-color: ${({ theme }) => theme.primaryBlue};
   border-radius: 3px;
   font-size: 1em;
   padding: 0 5px;
   font-weight: 600;

   & + & {
      margin-left: 5px;
   }

   &:hover {
      cursor: pointer;
   }
   @media only screen and (max-width: 400px) {
      font-size: 0.7em;
   }
`;
