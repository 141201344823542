import React, { useEffect, useContext, useState, Fragment } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import { Store } from 'state/store';
import { fetchCategories } from 'state/actions/ResourceActions';
import styled from 'styled-components';
import PageHeading from 'components/interface/Heading/PageHeading';
import { Tag } from 'network/EstarApi/Resources';
import { ResourceEvents, FilterEvents } from 'state/constants';
import { ViewContainer } from 'components/SharedStyledComponents';
import Loading from 'Elements/Loading';

const Categories: React.FC<RouteComponentProps> = (): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const {
      state: { resourceState },
      dispatch,
   } = useContext(Store);

   const fetchResourceCategories = async () => {
      setLoading(true);
      await fetchCategories(dispatch);
      setLoading(false);
   };

   useEffect(() => {
      dispatch({ type: FilterEvents.CLEAR_FILTERS });
      fetchResourceCategories();
   }, []);

   return (
      <ViewContainer>
         {loading ? (
            <Loading />
         ) : (
            <Fragment>
               <PageHeading title="Categories" resultCount={resourceState.categories.length || 0} />
               <Container>
                  {resourceState.categories.map(
                     ({ id, displayName }: Tag): JSX.Element => (
                        <Category
                           key={id}
                           to={`/categories/${id}`}
                           onClick={() => dispatch({ type: ResourceEvents.SET_SELECTED_CATEGORY, payload: displayName })}>
                           <CategoryName>{displayName}</CategoryName>
                        </Category>
                     )
                  )}
               </Container>
            </Fragment>
         )}
      </ViewContainer>
   );
};

/**
 * 'advocacy, fundraising' -> 'advocacy,+fundraising'
 * 'colon/rectum cancer' -> 'colon+rectum+cancer'
 */
export function formatQuery(query: string): string {
   return query.replace(/(\s|\/)/g, '+').toLowerCase();
}

export default Categories;

const Container = styled.div`
   display: grid;
   grid-template-columns: repeat(auto-fill, 200px);
   grid-gap: 1em;
   justify-content: space-between;
   padding-right: 25px;
   margin: 0 auto;
   width: 100%;
   @media only screen and (max-width: 400px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
   }
`;

const Category = styled(Link)`
   border-radius: 4px;
   background-color: ${({ theme }) => theme.primaryWhite};
   border: 1px solid ${({ theme }) => theme.secondaryGray};
   box-shadow: ${({ theme }) => theme.boxShadow};
   margin: 1em;
   text-decoration: none;
   text-align: center;
   padding: 10px;
   width: 200px;
   height: 150px;
   display: flex;
   color: ${({ theme }) => theme.almostBlack};
   justify-content: center;
   flex-direction: column;
   &:hover {
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.secondaryBlue};
      transition: ease all 150ms;
      color: ${({ theme }) => theme.secondaryBlue};
   }
   @media only screen and (max-width: 400px) {
      width: 250px;
      height: 120px;
   }
`;

const CategoryName = styled.h3`
   color: inherit;
   font-size: 1.2em;
   text-decoration: none;
   font-weight: 500;
   line-height: 1.3em;
`;
