import React, { useRef, Fragment, useEffect, useContext, ReactNode } from 'react';
import Sidebar from 'react-sidebar';
import styled from 'styled-components';
import useViewport from 'hooks/useViewport';
import Icon, { IconType } from './icons/IconProvider';
import { Store } from 'state/store';
import { OPEN_SIDEBAR, CLOSE_SIDEBAR, FilterEvents } from 'state/constants';
import { applyFilters } from 'state/actions/FilterActions';
import { theme } from 'styles/ThemeProvider';

interface Props {
   header: string;
   renderContent: any;
   children: ReactNode;
}

export default ({ header, renderContent, children }: Props) => {
   const sidebarRef = useRef(null);
   const className = 'sidebar';
   const [{ width: windowWidth }] = useViewport();
   const {
      state: { sidebarState, filterState, userState },
      dispatch,
   } = useContext(Store);

   const handleOutsideClick = (e: any): void => {
      if (e.target.classList.contains(className)) {
         dispatch({ type: CLOSE_SIDEBAR, payload: false });
      }
   };

   useEffect(() => {
      !sidebarState && Boolean(filterState.query) && applyFilters(dispatch, filterState.query);
   }, [filterState.query, sidebarState]);

   useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
      return () => {
         document.removeEventListener('click', handleOutsideClick);
      };
   }, []);

   return (
      <Sidebar
         sidebar={
            <Fragment>
               <SidebarHeader>
                  <Header>{header}</Header>
                  <IconButton
                     onClick={() => {
                        dispatch({ type: CLOSE_SIDEBAR, payload: false });
                        !sidebarState && Boolean(filterState.query) && applyFilters(dispatch, filterState.query);
                     }}>
                     <Icon type={IconType.close} color="#8f8e89" />
                  </IconButton>
               </SidebarHeader>
               {renderContent()}
            </Fragment>
         }
         contentId="sidebar"
         ref={sidebarRef}
         overlayClassName={className}
         open={sidebarState}
         onSetOpen={() => dispatch({ type: OPEN_SIDEBAR, payload: true })}
         styles={{
            sidebar: { background: theme.primaryOffWhite, width: windowWidth > 800 ? '50%' : '80%', zIndex: '100', height: '100%' },
            content: { background: theme.primaryOffWhite },
         }}
         pullRight={true}
         shadow={true}>
         {children}
      </Sidebar>
   );
};

const SidebarHeader = styled.div`
   height: 60px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   background: ${({ theme }) => theme.primaryWhite};
   position: sticky;
   top: 0;
   z-index: 100;
`;

const Header = styled.h2`
   font-size: 2em;
   color: ${({ theme }) => theme.almostBlack};
`;

const IconButton = styled.button.attrs({ type: 'button' })`
   position: absolute;
   left: 0;
   margin-right: 12px;
   background-color: transparent;
   border: none;
   font-size: 1.2em;
   padding: 13px;

   &:hover {
      cursor: pointer;
   }
`;
