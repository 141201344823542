import FetchWrapper from 'network/FetchWrapper';
import { FilterEvents as actions } from 'state/constants';
import { Dispatch } from 'state/store';
import AuthTokenManager from 'network/AuthTokenManager';
import { Resource } from 'network/EstarApi/Resources';

export const toggleFilter = (dispatch: Dispatch): void => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');
   dispatch({ type: actions.BUILD_FILTER_QUERY });
};

export const applyFilters = async (dispatch: Dispatch, query: string): Promise<void> => {
   const token: string | null = AuthTokenManager.getAccessToken();
   if (!token) throw new Error('Token is either missing or invalid');

   try {
      const response = await FetchWrapper({
         path: query,
         mode: 'cors',
         token,
      });
      const resources: Resource[] = await response.json();
      console.log('TCL: resources', resources);
      return dispatch({ type: actions.SET_FILTER_RESULTS, payload: resources });
   } catch (error) {
      console.error(error);
   }
};
