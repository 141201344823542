import React from 'react';
import styled from 'styled-components';
import { InputChange } from 'types/events';

interface Props {
   checked: boolean;
   onChange: (e: InputChange) => void;
   label: string;
}

const Checkbox: React.FC<Props> = ({ checked, label, onChange }): JSX.Element => (
   <CheckBoxLabel>
      <CheckboxContainer>
         <HiddenCheckbox checked={checked} onChange={onChange} />
         <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 24 24">
               <polyline points="20 6 9 17 4 12" />
            </Icon>
         </StyledCheckbox>
      </CheckboxContainer>
      <CheckboxSpanActingAsLabel checked={checked}>{label}</CheckboxSpanActingAsLabel>
   </CheckBoxLabel>
);

const CheckBoxLabel = styled.label`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-content: center;
`;
const CheckboxSpanActingAsLabel = styled.span`
   color: ${({ checked, theme }: { checked: boolean; theme: any }) => (checked ? theme.primaryGreen : 'black')};
   margin: 0 0 10px 10px;
   transition-duration: 250ms;
`;

const CheckboxContainer = styled.div`
   display: inline-block;
   vertical-align: middle;
`;

const Icon = styled.svg`
   fill: none;
   stroke: white;
   stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
   border: 0;
   clip: rect(0 0 0 0);
   clip-path: inset(50%);
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   white-space: nowrap;
   width: 1px;
   transition-duration: 200ms;
`;

const StyledCheckbox = styled.div`
   display: inline-block;
   width: 26px;
   height: 26px;
   background: ${({ checked, theme }: { checked: boolean; theme: any }) => (checked ? theme.primaryGreen : theme.primaryWhite)};
   border: 1px solid ${({ theme }) => theme.primaryGray};
   border-radius: 3px;
   transition: all 150ms;

   ${HiddenCheckbox}:focus + & {
      box-shadow: 0 0 0 3px pink;
   }

   ${Icon} {
      visibility: ${({ checked }: { checked: boolean }) => (checked ? 'visible' : 'hidden')};
   }
   &:hover {
      cursor: pointer;
      background-color: ${({ theme, checked }: { checked: boolean; theme: any }) => (checked ? theme.secondaryGreen : theme.primaryGray)};
   }
`;

export default Checkbox;
