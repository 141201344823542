import React, { useContext } from 'react';
import RadioButtonGroup from 'components/interface/Form/RadioButtonGroup';
import { Store } from 'state/store';
import { FilterEvents as actions } from 'state/constants';
import { FilterContainer } from 'components/SharedStyledComponents';
import { FilterKeys } from 'state/reducers/FIlterReducer';

const EvidenceBased: React.FC<{ filterTarget: FilterKeys }> = ({ filterTarget }): JSX.Element => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);

   const handleCheckboxClick = (checked: boolean | undefined) => {
      dispatch({ type: actions.TOGGLE_FILTER, payload: { filterTarget, checked } });
      return dispatch({ type: actions.BUILD_FILTER_QUERY });
   };
   return (
      <FilterContainer>
         <RadioButtonGroup
            answerChoices={[
               {
                  label: 'Evidence-Based',
                  checked: filterState[filterTarget] === true,
                  resolver: () => handleCheckboxClick(true),
               },
               {
                  label: 'Non-Evidence-Based',
                  checked: filterState[filterTarget] === false,
                  resolver: () => handleCheckboxClick(false),
               },
               {
                  label: 'Both',
                  checked: filterState[filterTarget] === undefined,
                  resolver: () => handleCheckboxClick(undefined),
               },
            ]}
         />
      </FilterContainer>
   );
};

export default EvidenceBased;
