// These functions wrap Mixpanel functions so they
// only fire on production.
// If you need more functionality add it from:
//https://developer.mixpanel.com/docs/javascript-full-api-reference
import mixpanel from 'mixpanel-browser';
import { SESSION_END, SESSION_START } from './AnalyticEvents';

type id = string;

const mixpanelToken = '6344f7354798e629846b5f5e20544369';
let initialized = false;
let env_check = process.env.NODE_ENV === 'production';

class Mixpanel {
   init = () => {
      if (!initialized) {
         mixpanel.init(mixpanelToken);
         this.track(SESSION_START);
         this.time_event(SESSION_END);
         window.addEventListener('beforeunload', this.endSession);
      }
      initialized = true;
   };

   identify = (id: id) => {
      if (env_check) mixpanel.identify(id);
   };
   alias = (id: id) => {
      if (env_check) mixpanel.alias(id);
   };
   track = (name: string, props?: Object) => {
      if (env_check) mixpanel.track(name, props);
   };

   time_event = (eventName: string) => {
      if (env_check) mixpanel.time_event(eventName);
   };

   people = {
      set: (props: Object) => {
         if (env_check) mixpanel.people.set(props);
      },
   };

   register_once = (properties: Object) => {
      if (env_check) mixpanel.register_once(properties);
   };

   endSession = () => {
      // We want to track when a user's session "ends"
      // That includes: logging out, closing the browser window, etc.
      // Mixpanel doesn't support his out of the box, so this is a bit of a hack.

      // This solution scrapes data out of Mixpanel's internals, builds an event
      // to track from it, and then converts into Mixpanel's desired format,
      // a base-64 encoded ASCII string.

      // We then send this data using sendBeacon, which specifically allows us to send data
      // after the page closes.
      //
      // This solution is derived from the info in this Github issue:
      // https://github.com/mixpanel/mixpanel-js/issues/184

      // In order to properly build the data to send to Mixpanel, we need to scrape
      // some data out of its interanls. These have no types so we need to tell
      // TS to ignore our probing.

      // This relies on a timer being set using mixpanel.time_event for our given event.
      // We compute a duration by calculating right now against the timer start.

      if (env_check) {
         //@ts-ignore
         let duration = new Date().getTime() - mixpanel.persistence.props.__timers[SESSION_END];
         duration = parseFloat((duration / 1000).toFixed(3));

         // Get properties we want to send up from the Mixpanel object
         //@ts-ignore
         const { distinct_id } = mixpanel.persistence.props;
         //@ts-ignore
         const properties = mixpanel._.info.properties();
         //@ts-ignore
         const persistenceProperties = mixpanel.persistence.properties();

         const data = {
            event: SESSION_END,
            properties: {
               token: mixpanelToken,
               distinct_id,
               $duration: duration,
               ...properties,
               ...persistenceProperties,
            },
         };

         // In order to get it to an ASCII string we have to first make it a byte string,
         // which stringify handles for us.
         const dataInAsciiFormat = btoa(JSON.stringify(data));
         navigator.sendBeacon('https://api.mixpanel.com/track/?data=' + dataInAsciiFormat);
      }
   };
}

export default new Mixpanel();
