import { Cancer, CancerType } from 'state/user-meta';
import { Action, Reducer } from 'state/store';
import { FilterEvents as actions } from 'state/constants';
import { Filters, PurposeStrings, PlatformStrings, Purpose, DeveloperTypeStrings } from 'network/EstarApi/Filters';
import { deriveTruth } from 'utility/ArrayUtils';
import { Resource } from 'network/EstarApi/Resources';

//NOTE: all property ids correspond to their respective Tags/Category
const defaultPurposeOptions: Purpose = {
   [PurposeStrings.general_cancer_education]: { checked: false, id: 13 },
   [PurposeStrings.treatment_specific_info]: { checked: false, id: 14 },
   [PurposeStrings.risk_detection_recurrence]: { checked: false, id: 15 },
   [PurposeStrings.healthcare_management]: { checked: false, id: 16 },
   [PurposeStrings.symptom_tracking]: { checked: false, id: 17 },
   [PurposeStrings.cancer_in_youth]: { checked: false, id: 18 },
   [PurposeStrings.community_social_support]: { checked: false, id: 19 },
   [PurposeStrings.spirituality]: { checked: false, id: 20 },
   [PurposeStrings.advocacy_fundraising]: { checked: false, id: 21 },
};
const defaultCancerTypeOptions: CancerType = {
   [Cancer.lung]: { checked: false, id: 1 },
   [Cancer.breast]: { checked: false, id: 2 },
   [Cancer.prostate]: { checked: false, id: 3 },
   [Cancer.colon_rectum]: { checked: false, id: 4 },
   [Cancer.ovarian]: { checked: false, id: 5 },
   [Cancer.cervical]: { checked: false, id: 6 },
   [Cancer.cervix]: { checked: false, id: 7 },
   [Cancer.kidney]: { checked: false, id: 8 },
   [Cancer.pancreatic]: { checked: false, id: 9 },
   [Cancer.liver]: { checked: false, id: 10 },
   [Cancer.leukemia]: { checked: false, id: 11 },
   [Cancer.non_hodgkin_lymphoma]: { checked: false, id: 12 },
};
const defaultPlatformOptions = {
   [PlatformStrings.web]: { checked: false, id: 1 },
   [PlatformStrings.ios]: { checked: false, id: 2 },
   [PlatformStrings.android]: { checked: false, id: 3 },
};

const defaultDeveloperTypeOptions = {
   [DeveloperTypeStrings.academic]: { checked: false },
   [DeveloperTypeStrings.commercial]: { checked: false },
   [DeveloperTypeStrings.not_for_profit]: { checked: false },
   [DeveloperTypeStrings.hospital_based]: { checked: false },
};

export enum FilterKeys {
   purpose = 'purpose',
   cancer_type = 'cancer_type',
   evidence_based = 'evidence_based',
   platform = 'platform',
   cost = 'cost',
   privacy_policy_url = 'privacy_policy_url',
   developer_type = 'developer_type',
}

const initialState: Filters = {
   [FilterKeys.purpose]: defaultPurposeOptions,
   [FilterKeys.cancer_type]: defaultCancerTypeOptions,
   [FilterKeys.evidence_based]: undefined,
   [FilterKeys.platform]: defaultPlatformOptions,
   [FilterKeys.cost]: undefined,
   [FilterKeys.privacy_policy_url]: false,
   [FilterKeys.developer_type]: defaultDeveloperTypeOptions,
   query: '',
   filterResults: [],
};

//only used for sort
export enum FilterOptions {
   recommended = 'recommended',
   alphabetical = 'alphabetical',
   reverse_alphabetical = 'reverse_alphabetical',
   rating = 'rating',
   price = 'price',
}

const reducer: Reducer = (state: any = initialState, action: Action) => {
   const { type, payload } = action;
   switch (type) {
      case actions.TOGGLE_FILTER:
         let newFilterState: Filters;
         //handle radio buttons && dev type differently than checkboxes
         if (
            payload.filterTarget === FilterKeys.evidence_based ||
            payload.filterTarget === FilterKeys.cost ||
            payload.filterTarget === FilterKeys.privacy_policy_url
         ) {
            newFilterState = {
               ...state,
               [payload.filterTarget]: payload.checked as boolean,
            };
         } else {
            newFilterState = {
               ...state,
               [payload.filterTarget]: {
                  ...state[payload.filterTarget],
                  [payload.name]: {
                     ...state[payload.filterTarget][payload.name],
                     checked: !state[payload.filterTarget][payload.name].checked as boolean,
                  },
               },
            };
         }

         return newFilterState;
      case actions.BUILD_FILTER_QUERY:
         const checkBoxData = { props: { target: 'checked', desired: 'id' } },
            { purpose, cancer_type, evidence_based, platform, cost, privacy_policy_url, developer_type } = state,
            appliedPurposeFilters: number[] = deriveTruth({ obj: purpose, ...checkBoxData }), // -> [tagIds]
            appliedCancerFilters: number[] = deriveTruth({ obj: cancer_type, ...checkBoxData }), // -> [tagIds]
            appliedPlatformFilters: number[] = deriveTruth({ obj: platform, ...checkBoxData }), // -> [tagIds]
            tagIds: number[] = [...appliedPurposeFilters, ...appliedCancerFilters],
            appliedDeveloperTypeFilters: DeveloperTypeStrings[] = determineDeveloperTypeFilter(developer_type);
         const query = `${determineBaseQueryUrl()}${tagIds.map((id: number): string => `tags.id_in=${id}`).join('&')}${
            evidence_based !== undefined ? `&evidenceBased=${evidence_based}` : ''
         }${appliedPlatformFilters.map((id: number): string => `&platformlinks.platform.id_in=${id}`).join('&')}${determineCostFilter(cost)}${
            privacy_policy_url ? '&privacyPolicyUrl_ne=""' : ''
         }${appliedDeveloperTypeFilters.map((type: DeveloperTypeStrings) => `publisher.type=${type}`).join('&')}`;
         return { ...state, query };
      case actions.SET_FILTER_RESULTS:
         return { ...state, filterResults: payload as Resource[] };
      case actions.CLEAR_FILTERS:
         return initialState as Filters;
      default:
         return state as Filters;
   }
};

function determineBaseQueryUrl(): string {
   const { pathname } = window.location;
   let baseUrl: string = '';
   if (pathname.startsWith('/search')) {
      const searchQuery = pathname.split('/')[pathname.split('/').length - 1];
      //ensure search query is include in filtered results
      baseUrl = `/search?text=${searchQuery}`;
   } else if (pathname.startsWith('/categories')) {
      const categoryId = pathname.split('/')[pathname.split('/').length - 1];
      //ensure selected category is include in filtered results
      baseUrl = `/resources?tags.id=${categoryId}`;
   } else if (pathname.startsWith('/favorites')) {
      baseUrl = '/favorites?';
   } else {
      baseUrl = '/resources?';
   }
   return baseUrl;
}

function determineCostFilter(cost: boolean | undefined): string {
   let appliedCostFilter: string = '';
   if (cost === true) {
      //"free" id == 1, so we want all other price points except free
      appliedCostFilter = '&platformlinks.pricingmodel.id_ne=1';
   } else if (cost === false) {
      appliedCostFilter = '&platformlinks.pricingmodel.id_eq=1';
   }
   return appliedCostFilter;
}

function determineDeveloperTypeFilter(developerTypes: any): DeveloperTypeStrings[] | [] {
   const filters: DeveloperTypeStrings[] = [];
   for (const type in developerTypes) {
      if (developerTypes[type].checked) {
         filters.push(type as DeveloperTypeStrings);
      }
   }
   return filters;
}

export default { initialState, reducer };
