import React from 'react';
import styled from 'styled-components';
import { InputChange } from 'types/events';

interface Props {
   checked: boolean;
   onChange: (e: InputChange) => void;
   label: string;
   name?: string;
   value?: string;
}

const RadioButton: React.FC<Props> = ({ checked, label, onChange, name = '', value = '' }): JSX.Element => (
   <Container checked={checked}>
      {label}
      <HiddenRadioButton name={name} checked={checked} onChange={onChange} value={value} />
      <SpanActingAsRadioButton />
   </Container>
);

export default RadioButton;

const Container = styled.label`
   display: block;
   position: relative;
   padding-left: 35px;
   margin-bottom: 5px;
   cursor: pointer;
   font-size: 16px;
   -webkit-user-select: none;
   transition: all 150ms;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   color: ${({ checked, theme }: { checked: boolean; theme: any }) => (checked ? theme.primaryGreen : theme.almostBlack)};
   &:hover span {
      background: ${({ theme }) => theme.primaryGray};
   }

   span:after {
      top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
   }
`;

const HiddenRadioButton = styled.input.attrs(({ checked }: { checked: boolean }) => ({ type: 'radio', checked }))`
   position: absolute;
   opacity: 0;
   cursor: pointer;
   transition-duration: 150ms;

   &:checked ~ span {
      background-color: ${({ theme }) => theme.primaryGreen};
   }

   &:checked ~ span:after {
      display: block;
   }
`;

const SpanActingAsRadioButton = styled.span`
   position: absolute;
   top: 4px;
   left: 0;
   height: 25px;
   width: 25px;
   background-color: #eee;
   border-radius: 50%;

   ::after {
      content: '';
      position: absolute;
      display: none;
   }
`;
