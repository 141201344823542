import React, { useContext, Fragment } from 'react';
import styled from 'styled-components';
import Dropdown from 'components/interface/Form/FilterDropdown';
import { RouteComponentProps } from '@reach/router';
import Flex from 'Elements/Flex';
import { Button } from 'components/SharedStyledComponents';
import { FilterEvents, CLOSE_SIDEBAR } from 'state/constants';
import { Store } from 'state/store';
import useViewport from 'hooks/useViewport';
import Purpose from 'components/interface/Filters/Purpose';
import CancerType from 'components/interface/Filters/CancerType';
import { FilterKeys } from 'state/reducers/FIlterReducer';
import EvidenceBased from 'components/interface/Filters/EvidenceBased';
import Cost from 'components/interface/Filters/Cost';
import Platform from 'components/interface/Filters/Platform';
import { applyFilters } from 'state/actions/FilterActions';
import PrivacyPolicyUrl from 'components/interface/Filters/PrivacyPolicyUrl';
import DeveloperType from 'components/interface/Filters/DeveloperType';

export enum FilterType {
   Purpose = 'Purpose',
   CancerType = 'Cancer Type',
   DemographicGroup = 'Demographic Group',
   EvidenceBased = 'Evidence-Based',
   Platform = 'Platform',
   Cost = 'Cost',
   PrivacyPolicy = 'Privacy Policy',
   DeveloperType = 'Developer Type',
}

const Filters: React.FC<RouteComponentProps> = (): JSX.Element => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);
   const [{ width: windowWidth }] = useViewport();

   return (
      <Flex
         flexDirection="column"
         flexWrap="no-wrap"
         backgroundColor="#F1F1F5"
         justifyContent="flex-start"
         position="relative"
         height="100%"
         alignItems="center">
         <Content>
            {/* so long as the user isn't searching or looking at favorites, allow to narrow filter by cancer type and resource selection */}
            {!window.location.pathname.startsWith('/search') ||
               (!window.location.pathname.startsWith('/favorites') && (
                  <Fragment>
                     <Dropdown filter={FilterType.Purpose}>
                        <Purpose filterTarget={FilterKeys.purpose} />
                     </Dropdown>
                     <Dropdown filter={FilterType.CancerType}>
                        <CancerType filterTarget={FilterKeys.cancer_type} />
                     </Dropdown>
                  </Fragment>
               ))}
            <Dropdown filter={FilterType.EvidenceBased}>
               <EvidenceBased filterTarget={FilterKeys.evidence_based} />
            </Dropdown>
            <Dropdown filter={FilterType.Platform}>
               <Platform filterTarget={FilterKeys.platform} />
            </Dropdown>
            <Dropdown filter={FilterType.Cost}>
               <Cost filterTarget={FilterKeys.cost} />
            </Dropdown>
            <Dropdown filter={FilterType.PrivacyPolicy}>
               <PrivacyPolicyUrl filterTarget={FilterKeys.privacy_policy_url} />
            </Dropdown>
            <Dropdown filter={FilterType.DeveloperType}>
               <DeveloperType filterTarget={FilterKeys.developer_type} />
            </Dropdown>
         </Content>
         <Flex flexDirection="row" justifyContent="center" backgroundColor="transparent" alignSelf="flex-start" alignItems="flex-start">
            <Button
               color="primary-gray"
               fontSize={windowWidth > 600 ? '1.2em' : '0.9em'}
               margin=".5em 1em"
               onClick={() => dispatch({ type: FilterEvents.CLEAR_FILTERS })}>
               Clear All
            </Button>
            <Button
               color="primary-orange"
               fontSize={windowWidth > 600 ? '1.2em' : '0.9em'}
               margin=".5em 1em"
               onClick={() => {
                  dispatch({ type: CLOSE_SIDEBAR, payload: false });
                  applyFilters(dispatch, filterState.query);
               }}>
               OK, I'm done!
            </Button>
         </Flex>
      </Flex>
   );
};

const Content = styled.div`
   width: 100%;
`;
export default Filters;
