import React, { useEffect, useContext, Fragment, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Store } from 'state/store';
import { fetchCategory } from 'state/actions/ResourceActions';
import { ViewContainer, Alerts, CardLayout } from 'components/SharedStyledComponents';
import PageHeading from 'components/interface/Heading/PageHeading';
import Flex from 'Elements/Flex';
import { Resource } from 'network/EstarApi/Resources';
import Loading from 'Elements/Loading';
import ResourceCard from 'components/interface/Resource/ResourceCard/ResourceCard';

//todo: address TS issue with making name prop required
interface Props extends RouteComponentProps {
   id?: string;
}
const Category: React.FC<Props> = ({ id }): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const {
      state: { resourceState, filterState },
      dispatch,
   } = useContext(Store);
   const [contentToRender, setContentToRender] = useState<Resource[]>(resourceState.selectedCategory.resources);

   const fetchCategoryById = async () => {
      setLoading(true);
      id && (await fetchCategory(dispatch, id));
      setLoading(false);
   };

   useEffect(() => {
      Boolean(filterState.filterResults) && setContentToRender(filterState.filterResults);
   }, [filterState.filterResults]);

   useEffect(() => {
      fetchCategoryById();
   }, []);

   useEffect(() => {
      setContentToRender(resourceState.selectedCategory.resources);
   }, [resourceState.selectedCategory.resources]);

   return (
      <ViewContainer>
         <Fragment>
            <PageHeading title={resourceState.selectedCategory.displayName} resultCount={contentToRender.length || 0} breadCrumbs={true} />
            {loading ? (
               <Loading />
            ) : !contentToRender.length && !loading ? (
               <Alerts.InfoMessage>
                  <p>There are currently no resources for this category to display</p>
               </Alerts.InfoMessage>
            ) : (
               <ColumnsWrapper>
                  {contentToRender.map(
                     (resource: Resource): JSX.Element => (
                        <CardColumnContainer key={resource.id}>
                           <CardColumn>
                              <ResourceCard {...resource} />
                           </CardColumn>
                        </CardColumnContainer>
                     )
                  )}
               </ColumnsWrapper>
            )}
         </Fragment>
      </ViewContainer>
   );
};
export default Category;

const { CardColumnContainer, CardColumn, ColumnsWrapper } = CardLayout;
