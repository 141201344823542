import React, { Fragment, useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Resource } from 'network/EstarApi/Resources';
import { ViewContainer, Alerts, CardLayout } from 'components/SharedStyledComponents';
import PageHeading from 'components/interface/Heading/PageHeading';
import Loading from 'Elements/Loading';
import Flex from 'Elements/Flex';
import ResourceCard from 'components/interface/Resource/ResourceCard/ResourceCard';
import { fetchSearchResults } from 'state/actions/ResourceActions';
import { Store } from 'state/store';
import styled from 'styled-components';

interface Props extends RouteComponentProps {
   query?: string;
}

const SearchResults: React.FC<Props> = ({ query }): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const {
      state: { resourceState, filterState },
      dispatch,
   } = useContext(Store);
   const [contentToRender, setContentToRender] = useState<Resource[]>(resourceState.search.results);

   const fetchUserSearchResults = async () => {
      setLoading(true);
      await fetchSearchResults(dispatch, query as string);
      setLoading(false);
   };

   useEffect(() => {
      Boolean(filterState.filterResults.length) && setContentToRender(filterState.filterResults);
   }, [filterState.filterResults]);

   useEffect(() => {
      fetchUserSearchResults();
   }, [query]);

   useEffect(() => {
      setContentToRender(resourceState.search.results);
   }, [resourceState.search.results]);

   return (
      <ViewContainer>
         {loading ? (
            <Loading />
         ) : (
            <Fragment>
               {!loading && (
                  <InfoMessage>
                     <p>Whoops! Looks like we couldn't find what you were looking for :( </p>
                  </InfoMessage>
               )}
            </Fragment>
         )}
      </ViewContainer>
   );
};

const InfoMessage = styled(Alerts.InfoMessage)`
   margin-top: 20px;
`;

export default SearchResults;
