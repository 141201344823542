import React, { ReactNode, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import useToggle from 'hooks/useToggle';
import Icon, { IconType } from 'components/interface/icons/IconProvider';
import { FilterType } from 'components/views/Filters';
import { Store } from 'state/store';

interface Props {
   filter: FilterType;
   children: ReactNode | JSX.Element;
}

const Dropdown: React.FC<Props> = ({ filter, children }): JSX.Element => {
   const {
      state: { sidebarState },
   } = useContext(Store);
   const [open, setOpen] = useToggle(sidebarState);

   useEffect(() => {
      //ensure filter dropdowns are closed when sidebar is closed
      if (sidebarState === false && open) setOpen(false);
   }, [sidebarState]);
   return (
      <DropdownContainer>
         <LabelContainer onClick={() => setOpen(!open)}>
            <Filter>{filter}</Filter>
            <Fragment>
               <Icon type={open ? IconType.chevronUp : IconType.chevronDown} />
            </Fragment>
         </LabelContainer>
         <ContentContainer visible={open}>{children}</ContentContainer>
      </DropdownContainer>
   );
};

export default Dropdown;

const DropdownContainer = styled.div`
   background: inherit;
   padding: 5px 10px;
`;

const LabelContainer = styled.div`
   height: 80px;
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 1em;
   margin: 0.4em auto;
   background: white;
   color: black;
   box-shadow: ${({ theme }) => theme.boxShadow};
   align-items: center;
   cursor: pointer;
`;

const Filter = styled.div`
   font-size: 1.4em;
`;

const Suggestion = styled.small`
   color: gray;
   font-size: 0.7em;
   display: block;
`;

const ContentContainer = styled.div`
   display: ${({ visible }: { visible: boolean }) => (visible ? 'flex' : 'none')};
   justify-content: flex-start;
   background: white;
   margin: -10px auto 10px auto;
`;
