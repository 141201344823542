import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Logo, { LogoOptions } from 'components/interface/icons/Logo';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import { FormSubmission, InputChange } from 'types/events';
import { loginUser } from 'state/actions/UserActions';
import { Store } from 'state/store';
import { LoginOnboardingComponents as LoginComponents, Alerts } from 'components/SharedStyledComponents';
import Input from 'components/interface/Form/Input';

type LoginType = { identifier: string; password: string };
const Login: React.FC<RouteComponentProps> = (): JSX.Element => {
   const [user, setUser] = useState<LoginType>({ identifier: '', password: '' });
   const {
      state: { userState },
      dispatch,
   } = useContext(Store);

   const handleFormSubmit = useCallback(
      async (e: FormSubmission) => {
         e.preventDefault();
         const userProfile = await loginUser(dispatch, user);
         return userProfile ? navigate('/recommended') : navigate('/onboarding');
      },
      [user, dispatch]
   );

   const handleInput = (e: InputChange) => setUser({ ...user, [e.target.name]: e.target.value });

   return (
      <Container>
         <Header>
            <Logo logo={LogoOptions.vertical} height={200} />
         </Header>

         <FormContainer>
            <Form onSubmit={handleFormSubmit}>
               <Input
                  label="Username"
                  value={user.identifier}
                  name="identifier"
                  onChange={handleInput}
                  size="full"
                  errorMessage="Username is required"
                  wrapperMargin="15px 0 0 0"
               />
               <Input
                  label="Password"
                  value={user.password}
                  name="password"
                  onChange={handleInput}
                  size="full"
                  type="password"
                  errorMessage="Password is required"
                  wrapperMargin="15px 0 0 0"
               />
               <LoginButton>LOGIN</LoginButton>
               {userState && userState.error && <Alerts.ErrorMessage>{userState.error}</Alerts.ErrorMessage>}
            </Form>
         </FormContainer>
         <LogoContainer>
            <Logo logo={LogoOptions.footer} height={150} />
         </LogoContainer>
      </Container>
   );
};

const { Container, Header, Form, FormContainer, LoginButton, LogoContainer } = LoginComponents;

export default Login;
