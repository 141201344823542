import React, { useContext, useEffect, useState, Fragment, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import { Store } from 'state/store';
import useToggle from 'hooks/useToggle';
import CheckboxGroup from 'components/interface/Form/CheckboxGroup';
import { UserMetaEvents as actions } from 'state/constants';
import { ViewContainer, CardLayout, Alerts } from 'components/SharedStyledComponents';
import { fetchProfile, updateProfile } from 'state/actions/UserActions';
import Loading from 'Elements/Loading';
import Flex from 'Elements/Flex';
import { RequestedResources } from 'state/user-meta';

enum button {
   update = 'update',
   cancel = 'cancel',
   edit = 'edit',
}

const Profile: React.FC<RouteComponentProps> = (): JSX.Element => {
   const {
      state: {
         userState: { currentUser },
      },
      dispatch,
   } = useContext(Store);
   const [loading, setLoading] = useState<boolean>(false);
   const [isEditing, setIsEditing] = useToggle(false);

   const fetchUserProfile = async (): Promise<void> => {
      setLoading(true);
      fetchProfile(dispatch);
      setLoading(false);
   };

   useEffect(() => {
      fetchUserProfile();
      determineSelectedResources();
   }, []);

   const determineSelectedResources = useCallback(() => {
      //meta returned from server
      const { resourceSelections } = currentUser.profile;
      resourceSelections &&
         resourceSelections.forEach(({ option }: Selection) => {
            dispatch({ type: actions.SET_REQUESTED_RESOURCES, payload: option });
         });
   }, [currentUser.meta.requestedResources]);

   return (
      <ViewContainer>
         {loading || !currentUser.profile ? (
            <Loading />
         ) : (
            <Flex justifyContent="center" flexDirection="row" margin="1em 0">
               <ColumnsWrapper>
                  <CardColumnContainer>
                     <CardColumn>
                        <ProfileCardContainer>
                           <Flex flexDirection="column" justifyContent="flex-start" alignItems="center">
                              <Username>{currentUser.username || 'Username'}</Username>
                              {!isEditing ? (
                                 <Fragment>
                                    <SubHeader> Here are your current interests</SubHeader>
                                    <InterestList>
                                       {currentUser.profile &&
                                          currentUser.profile.resourceSelections &&
                                          currentUser.profile.resourceSelections.map(
                                             ({ option, id }: Selection): JSX.Element => <Interest key={id}>{option}</Interest>
                                          )}
                                    </InterestList>
                                 </Fragment>
                              ) : (
                                 <CheckboxGroup
                                    textAlign="left"
                                    answerChoices={[
                                       {
                                          label: RequestedResources.treatment_specific,
                                          checked: currentUser.meta.requestedResources[RequestedResources.treatment_specific].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.treatment_specific,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.cancer_risk_detection_recurrence,
                                          checked:
                                             currentUser.meta.requestedResources[RequestedResources.cancer_risk_detection_recurrence].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.cancer_risk_detection_recurrence,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.healthcare_management,
                                          checked: currentUser.meta.requestedResources[RequestedResources.healthcare_management].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.healthcare_management,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.symptom_tracking_management_resources,
                                          checked:
                                             currentUser.meta.requestedResources[RequestedResources.symptom_tracking_management_resources]
                                                .checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.symptom_tracking_management_resources,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.cancer_in_youth,
                                          checked: currentUser.meta.requestedResources[RequestedResources.cancer_in_youth].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.cancer_in_youth,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.community_social_support,
                                          checked: currentUser.meta.requestedResources[RequestedResources.community_social_support].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.community_social_support,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.emotional_spiritual_support,
                                          checked: currentUser.meta.requestedResources[RequestedResources.emotional_spiritual_support].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.emotional_spiritual_support,
                                             }),
                                       },
                                       {
                                          label: RequestedResources.advocacy_fundraising_resources,
                                          checked:
                                             currentUser.meta.requestedResources[RequestedResources.advocacy_fundraising_resources].checked,
                                          resolver: () =>
                                             dispatch({
                                                type: actions.SET_REQUESTED_RESOURCES,
                                                payload: RequestedResources.advocacy_fundraising_resources,
                                             }),
                                       },
                                    ]}
                                 />
                              )}
                              <Flex flexDirection="row" justifyContent="center" alignItems="center">
                                 <Button
                                    onClick={() => setIsEditing(!isEditing)}
                                    margin={!isEditing ? '0 auto' : '0 0.5em'}
                                    name={!isEditing ? button.edit : button.cancel}>
                                    {!isEditing ? 'Edit interests' : 'Cancel'}
                                 </Button>
                                 {isEditing && (
                                    <Button
                                       name={button.update}
                                       margin={!isEditing ? '0 auto' : '0 0.5em'}
                                       onClick={() => updateProfile(dispatch, currentUser.meta.requestedResources)}>
                                       Update
                                    </Button>
                                 )}
                              </Flex>
                           </Flex>
                        </ProfileCardContainer>
                     </CardColumn>
                  </CardColumnContainer>
               </ColumnsWrapper>
            </Flex>
         )}
      </ViewContainer>
   );
};

const { CardColumnContainer, CardColumn, CardContainer, ColumnsWrapper } = CardLayout;

interface Selection {
   created_at: string;
   updated_at: string;
   id: number;
   option: string;
   tag: number;
}

interface NormalizedSelection {
   [option: string]: {
      checked: boolean;
      id: number;
   };
}

//this isn't ideal, but the differing property names and data shape between front & backend make it necessary. Conforming the shapes without normalization would require relatively significant overhead
export function normalizeResourceSelections(selections: Selection[]): NormalizedSelection {
   return selections.reduce((normalizedSelections, { tag, option }: Selection) => {
      //@ts-ignore
      normalizedSelections[option.toLowerCase()] = { checked: true, id: tag };
      return normalizedSelections;
   }, {});
}

export default Profile;

const ProfileCardContainer = styled(CardContainer)`
   padding: 1em 2em;
   margin: 1em auto;
`;
const SubHeader = styled.h6`
   color: ${({ theme }) => theme.primaryBlue};
   font-size: 1.3em;
`;

const Username = styled.h3`
   font-size: 1.5em;
   font-weight: bold;
   display: inline-block;
`;

const InterestList = styled.ul`
   list-style: none;
   margin: 0.5em 0;
   padding: 0.5em 1em;
`;

const Interest = styled.li`
   margin: 1em 0;
   text-align: left;
   color: ${({ theme }) => theme.primaryGreen};
`;
interface ButtonProps {
   width?: string;
   margin?: string;
   name: string;
}
const Button = styled.button.attrs({ type: 'button' })`
   border-radius: 20px;
   border: none;
   width: ${({ width = 'auto' }: ButtonProps) => width};
   padding: 9px 11px;
   align-self: center;
   font-size: 1.3em;
   background: ${({ theme, name }) => {
      switch (name) {
         case button.update:
            return theme.primaryGreen;
         case button.cancel:
            return theme.primaryRed;
         case button.edit:
            return theme.primaryOrange;
      }
   }};
   color: ${({ theme }) => theme.primaryWhite};
   font-weight: bold;
   margin: ${({ margin = '2em auto' }: ButtonProps) => margin};

   &:hover {
      background-color: ${({ theme, name }) => {
         switch (name) {
            case button.update:
               return theme.secondaryGreen;
            case button.cancel:
               return theme.secondaryRed;
            case button.edit:
               return theme.secondaryOrange;
         }
      }};
      cursor: pointer;
   }
`;
