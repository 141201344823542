import React, { ReactNode, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import Logo, { LogoOptions } from 'components/interface/icons/Logo';
import SearchBar from 'components/interface/Heading/SearchBar';
import Tabs from './Tabs';
import useViewport from 'hooks/useViewport';
import Icon, { IconType } from './icons/IconProvider';
import useToggle from 'hooks/useToggle';
import NavDropdown from './NavDropdown';
import Sidebar from 'components/interface/Sidebar';
import Filters from 'components/views/Filters';
import { Store } from 'state/store';
import { Alerts, ViewContainer } from 'components/SharedStyledComponents';

const Header: React.FC<{ children: ReactNode | JSX.Element }> = ({ children }): JSX.Element => {
   const [isSearching, setIsSearching] = useToggle(false);
   const [{ width: windowWidth }] = useViewport();
   const {
      state: { resourceState },
   } = useContext(Store);

   useEffect(() => {
      if (!window.location.pathname.startsWith('/search') && Boolean(resourceState.search.query)) {
         isSearching && setIsSearching(false);
      }
   }, [window.location.pathname]);

   useEffect(() => {
      //if mobile search is taking place and window is expanded, close search bar
      windowWidth >= 1290 && isSearching && setIsSearching(false);
   }, [windowWidth, isSearching, setIsSearching]);

   return (
      <Sidebar header="Filters" renderContent={Filters}>
         <HeaderContainer>
            <header>
               <Nav>
                  <NavContainer>
                     {!isSearching && (
                        <LogoContainer to={'/'}>
                           <SizedLogo logo={LogoOptions.horizontal_light} />
                        </LogoContainer>
                     )}
                     <MagnifierContainer>
                        <Icon type={IconType.magnifier} isButton={true} onClick={() => setIsSearching(!isSearching)} color="#FFFFFF" />
                     </MagnifierContainer>
                     {isSearching && <SearchBar icon={false} />}
                     <NavDropdown />
                  </NavContainer>
               </Nav>
               <Tabs />
            </header>
            <Main>
               {resourceState.error ? (
                  <ViewContainer>
                     <Alerts.ErrorMessage>{resourceState.error}</Alerts.ErrorMessage>
                  </ViewContainer>
               ) : (
                  children
               )}
            </Main>
         </HeaderContainer>
      </Sidebar>
   );
};

export default Header;

const LogoContainer = styled(Link)`
   margin: 0 auto;

   @media only screen and (min-width: 600px) {
      margin-left: 0;
   }
`;

const HeaderContainer = styled.section`
   display: flex;
   flex-direction: column;
`;

const Main = styled.main`
   height: 100%;
   width: 100%;
   background: ${({ theme }) => theme.primaryOffWhite};
`;

const Nav = styled.nav`
   vertical-align: middle;
   height: 100px;
   position: relative;
   background: ${({ theme }) => theme.primaryBlue}; /* Old browsers */
   background: -moz-linear-gradient(top, ${({ theme }) => theme.secondaryBlue} 20%, ${({ theme }) => theme.primaryDarker} 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(
      top,
      ${({ theme }) => theme.secondaryBlue} 0%,
      ${({ theme }) => theme.primaryDarker} 100%
   ); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom, ${({ theme }) => theme.secondaryBlue} 0%, ${({ theme }) => theme.primaryDarker} 100%);
`;

const MagnifierContainer = styled.div`
   position: absolute;
   right: 0;
   margin: 6px 0 0 40px;
   z-index: 99;

   @media only screen and (min-width: 600px) {
      right: 50px;
   }

   @media (min-width: 1290px) {
      display: none;
   }
`;

const NavContainer = styled.div`
   align-items: center;
   display: flex;
   position: relative;
   flex-direction: row;
   justify-content: space-between;
   height: 100%;
   max-width: 1200px;
   padding: 0 10px;
   margin: 0 auto;
`;

const SizedLogo = styled(Logo)`
   height: 30px;

   @media (min-width: 660px) {
      height: 70px;
   }
`;
