import React from 'react';
import styled from 'styled-components';
import NavLink from './Navlink';
import useViewport from 'hooks/useViewport';
import SearchBar from 'components/interface/Heading/SearchBar';
import Icon, { IconType } from './icons/IconProvider';
import { Link } from '@reach/router';

const Tabs: React.FC = (): JSX.Element => {
   const [{ width: windowWith }] = useViewport();
   //regex hackiness is hopefully temporary solution for coloring icons
   const activeColor: string = '#CC8141',
      inactiveColor: string = '#1390DB';

   return (
      <Container>
         <InnerContainer>
            {windowWith >= 1290 && <SearchBar width="auto" height="100%" />}
            <TabContainer>
               <LinkWrapper>
                  <UnderLineContainer active={/recommended/g.test(window.location.href)}>
                     <IconWrapper to="/recommended">
                        <Icon type={IconType.thumbs_up} color={/recommended/g.test(window.location.href) ? activeColor : inactiveColor} />
                     </IconWrapper>
                     <NavLink to="/recommended">Recommended</NavLink>
                  </UnderLineContainer>
               </LinkWrapper>

               <LinkWrapper>
                  <UnderLineContainer active={/categories/g.test(window.location.href)}>
                     <IconWrapper to="/categories">
                        <Icon type={IconType.grid} color={/categories/g.test(window.location.href) ? activeColor : inactiveColor} />
                     </IconWrapper>
                     <NavLink to="/categories">{windowWith > 1100 ? 'Browse By Category' : 'Categories'}</NavLink>
                  </UnderLineContainer>
               </LinkWrapper>

               <LinkWrapper>
                  <UnderLineContainer active={/favorites/g.test(window.location.href)}>
                     <IconWrapper to="/favorites">
                        <Icon
                           type={IconType.heart}
                           color={/favorites/g.test(window.location.href) ? activeColor : inactiveColor}
                           isButton={false}
                        />
                     </IconWrapper>
                     <NavLink to="/favorites">Favorites</NavLink>
                  </UnderLineContainer>
               </LinkWrapper>
            </TabContainer>
         </InnerContainer>
      </Container>
   );
};

export default Tabs;

const Container = styled.div`
   margin: 0 auto;
   padding: 0 10px;
   background-color: ${({ theme }) => theme.primaryWhite};
   border-bottom: 1.5px solid rgba(0, 0, 0, 0.09);
`;

const InnerContainer = styled.div`
   max-width: 1000px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-content: center;
   align-items: center;
   text-align: center;
   margin: 0 auto;
`;

const IconWrapper = styled(Link)`
   text-align: center;
   display: inline-flex;
   height: 30px;
   width: 30px;
   position: relative;
   justify-content: center;
   bottom: -3px;
   margin: 0;
`;

const TabContainer = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   max-width: 800px;
   padding-top: 10px;
`;

const UnderLineContainer = styled.div`
   border-bottom: 4px solid ${({ active }: { active: any }) => (active ? '#cc8141' : 'transparent')};
   padding: 0 5px 5px 0; //extra padding right prevents underline from looking too short
   position: relative;
   margin: 0;
   font-size: 0.8em;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;

   a {
      text-decoration: none;
      color: inherit;
      display: inline-block;
      line-height: 1em;
      padding-bottom: 2px;
   }

   @media only screen and (min-width: 400px) {
      //margin: 0 1em;
      font-size: 1em;
   }

   @media only screen and (min-width: 680px) {
      font-size: 1.5em;
      padding-bottom: 20px;
   }
`;

const LinkWrapper = styled.div`
   padding: 1em 0 0;
   margin-left: 1.5em;
   :first-child {
      margin-left: 0;
   }
`;
