import React, { useContext } from 'react';
import { DeveloperTypeStrings } from 'network/EstarApi/Filters';
import { FilterContainer } from 'components/SharedStyledComponents';
import { FilterEvents as actions } from 'state/constants';
import { Store } from 'state/store';
import CheckboxGroup from '../Form/CheckboxGroup';
import { FilterKeys } from 'state/reducers/FIlterReducer';

const DeveloperType: React.FC<{ filterTarget: FilterKeys }> = ({ filterTarget }): JSX.Element => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);

   const handleCheckboxClick = (name: DeveloperTypeStrings) => {
      dispatch({ type: actions.TOGGLE_FILTER, payload: { filterTarget, name } });
      return dispatch({ type: actions.BUILD_FILTER_QUERY });
   };
   return (
      <FilterContainer>
         <CheckboxGroup
            answerChoices={[
               {
                  label: DeveloperTypeStrings.commercial,
                  checked: filterState[filterTarget][DeveloperTypeStrings.commercial].checked,
                  resolver: () => handleCheckboxClick(DeveloperTypeStrings.commercial),
               },
               {
                  label: DeveloperTypeStrings.not_for_profit,
                  checked: filterState[filterTarget][DeveloperTypeStrings.not_for_profit].checked,
                  resolver: () => handleCheckboxClick(DeveloperTypeStrings.not_for_profit),
               },
               {
                  label: DeveloperTypeStrings.academic,
                  checked: filterState[filterTarget][DeveloperTypeStrings.academic].checked,
                  resolver: () => handleCheckboxClick(DeveloperTypeStrings.academic),
               },
               {
                  label: DeveloperTypeStrings.hospital_based,
                  checked: filterState[filterTarget][DeveloperTypeStrings.hospital_based].checked,
                  resolver: () => handleCheckboxClick(DeveloperTypeStrings.hospital_based),
               },
            ]}
         />
      </FilterContainer>
   );
};

export default DeveloperType;
