import React, { Fragment, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import ResourceCard from 'components/interface/Resource/ResourceCard/ResourceCard';
import { Store } from 'state/store';
import styled from 'styled-components';
import { fetchRecommended } from 'state/actions/ResourceActions';
import { Recommendation, Resource } from 'network/EstarApi/Resources';
import Flex from 'Elements/Flex';
import PageHeading from 'components/interface/Heading/PageHeading';
import { ViewContainer, CardLayout } from 'components/SharedStyledComponents';
import Loading from 'Elements/Loading';
import { FilterEvents, ResourceEvents } from 'state/constants';

const Recommended: React.FC<RouteComponentProps> = (): JSX.Element => {
   const {
      state: { resourceState },
      dispatch,
   } = useContext(Store);
   const [loading, setLoading] = useState<boolean>(false);
   const fetchUserRecommended = async (): Promise<void> => {
      setLoading(true);
      await fetchRecommended(dispatch);
      setLoading(false);
   };

   useEffect(() => {
      dispatch({ type: FilterEvents.CLEAR_FILTERS });
      fetchUserRecommended();
   }, []);

   return (
      <ViewContainer>
         {loading ? (
            <Loading />
         ) : (
            <Fragment>
               <PageHeading title="Recommended" resultCount={resourceState.resources.length || 0} />
               <ColumnsWrapper>
                  {resourceState.resources.map(
                     ({ category, resources, tagId }: Recommendation): JSX.Element => (
                        <CardColumnContainer key={category}>
                           <CardColumn>
                              <CardColumnHeader>
                                 <CardColumnCategory>{category.toUpperCase()}</CardColumnCategory>
                              </CardColumnHeader>
                              {!!resources.length ? (
                                 resources.map((resource: Resource): JSX.Element => <ResourceCard {...resource} key={resource.id} />)
                              ) : (
                                 <div>No available resources for this category</div>
                              )}
                           </CardColumn>
                           <SeeMoreButton
                              to={`/categories/${tagId}`}
                              onClick={() => dispatch({ type: ResourceEvents.SET_SELECTED_CATEGORY, payload: category })}>
                              + See more
                           </SeeMoreButton>
                        </CardColumnContainer>
                     )
                  )}
               </ColumnsWrapper>
            </Fragment>
         )}
      </ViewContainer>
   );
};

export default Recommended;

const { CardColumnContainer, CardColumnHeader, CardColumnCategory, CardColumn, ColumnsWrapper } = CardLayout;

const SeeMoreButton = styled(Link).attrs({ role: 'button' })`
   color: ${({ theme }) => theme.secondaryBlue};
   text-decoration: none;
   display: inline-block;
   align-self: center;
   width: auto;
   margin-bottom: 40px;
   &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.primaryBlue};
   }
`;
