import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import RadioButtonGroup from 'components/interface/Form/RadioButtonGroup';
import { UserMetaEvents as actions } from 'state/constants';
import { Store } from 'state/store';
import { Pages } from 'state/user-meta';
import { SetState } from 'types/events';

/**
|--------------------------------------------------
| QUESTION CONTENTS:
4) Are you looking for resources for yourself or someone else? -- radio button
         if (Myself) --> go to question 6 (Page 3)
         if (Someone else) --> go to question 5 (next question)
5) Are you a caregiver for someone diagnosed with cancer? -- radio button
         Note: regardless of this question's answer, prompt user to question 8 (Page 3)
         Other Note: question 8 will be on page 5 if user selects "Myself" for previous question
|--------------------------------------------------
*/
interface Props {
   personal: boolean | null;
   caregiver: boolean | null;
   setPagesComplete: SetState<Pages>;
}

const PageTwo: React.FC<Props> = ({ personal, caregiver, setPagesComplete }) => {
   const { dispatch } = useContext(Store);
   const determinePageCompleteness = useCallback(() => {
      //if user seeking personal resources && therefore is not a caregiver (caregiver question not answered & is thus null)
      //OR
      //user is NOT seeking personal && the caregiver question has been answered
      let completed: boolean;
      if ((personal && caregiver === null) || (personal === false && caregiver !== null)) {
         completed = true;
      } else {
         completed = false;
      }
      setPagesComplete((prevPages: Pages) => ({ ...prevPages, two: completed }));
   }, [personal, caregiver]);

   useEffect(() => {
      determinePageCompleteness();
   }, [personal, caregiver]);

   return (
      <Fragment>
         <RadioButtonGroup
            question="Are you looking for resources for yourself or someone else?"
            answerChoices={[
               {
                  label: 'Myself',
                  checked: personal === true,
                  resolver: () => dispatch({ type: actions.SET_PERSONAL, payload: true }),
               },
               {
                  label: 'Someone Else',
                  checked: personal === false,
                  resolver: () => dispatch({ type: actions.SET_PERSONAL, payload: false }),
               },
            ]}
         />
         {personal === false && (
            <RadioButtonGroup
               question="Are you a caregiver for someone diagnosed with cancer?"
               answerChoices={[
                  {
                     label: 'Yes',
                     checked: caregiver === true,
                     resolver: () => dispatch({ type: actions.SET_CAREGIVER, payload: true }),
                  },
                  {
                     label: 'No',
                     checked: caregiver === false,
                     resolver: () => dispatch({ type: actions.SET_CAREGIVER, payload: false }),
                  },
               ]}
            />
         )}
      </Fragment>
   );
};

export default PageTwo;
