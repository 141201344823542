import { TOKEN } from './../state/constants';
type tokenObj = {
   auth_time: number;
   exp: number;
   email: string;
   email_verified: boolean;
   iat: number;
   iss: string;
   sub: string;
   token_use: string;
};

class AuthenticationManager {
   clearTokens = () => {
      this.setAccessToken(null);
   };

   setAccessToken = (token: string | null) => {
      localStorage.setItem(TOKEN, token || '');
   };

   getAccessToken = () => {
      return localStorage.getItem(TOKEN);
   };
}

export default new AuthenticationManager();
