import React, { useContext } from 'react';
import CheckboxGroup from '../Form/CheckboxGroup';
import { PurposeStrings } from 'network/EstarApi/Filters';
import { Store } from 'state/store';
import { FilterEvents as actions } from 'state/constants';
import { FilterContainer } from 'components/SharedStyledComponents';
import { FilterKeys } from 'state/reducers/FIlterReducer';

const Purpose: React.FC<{ filterTarget: FilterKeys }> = ({ filterTarget }): JSX.Element => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);

   const handleCheckboxClick = (name: PurposeStrings) => {
      dispatch({ type: actions.TOGGLE_FILTER, payload: { filterTarget, name } });
      return dispatch({ type: actions.BUILD_FILTER_QUERY });
   };

   return (
      <FilterContainer>
         <CheckboxGroup
            answerChoices={[
               {
                  label: PurposeStrings.general_cancer_education,
                  checked: filterState[filterTarget][PurposeStrings.general_cancer_education].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.general_cancer_education),
               },
               {
                  label: PurposeStrings.treatment_specific_info,
                  checked: filterState[filterTarget][PurposeStrings.treatment_specific_info].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.treatment_specific_info),
               },
               {
                  label: PurposeStrings.risk_detection_recurrence,
                  checked: filterState[filterTarget][PurposeStrings.risk_detection_recurrence].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.risk_detection_recurrence),
               },
               {
                  label: PurposeStrings.healthcare_management,
                  checked: filterState[filterTarget][PurposeStrings.healthcare_management].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.healthcare_management),
               },
               {
                  label: PurposeStrings.symptom_tracking,
                  checked: filterState[filterTarget][PurposeStrings.symptom_tracking].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.symptom_tracking),
               },
               {
                  label: PurposeStrings.community_social_support,
                  checked: filterState[filterTarget][PurposeStrings.community_social_support].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.community_social_support),
               },
               {
                  label: PurposeStrings.spirituality,
                  checked: filterState[filterTarget][PurposeStrings.spirituality].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.spirituality),
               },
               {
                  label: PurposeStrings.advocacy_fundraising,
                  checked: filterState[filterTarget][PurposeStrings.advocacy_fundraising].checked,
                  resolver: () => handleCheckboxClick(PurposeStrings.advocacy_fundraising),
               },
            ]}
         />
      </FilterContainer>
   );
};

export default Purpose;
