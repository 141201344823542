import React, { Children, ReactNodeArray, useContext, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, navigate } from '@reach/router';
import { ButtonClick, SetState } from 'types/events';
import Flex from 'Elements/Flex';
import { Action, Store } from 'state/store';
import { LoginOnboardingComponents as OnboardingComponents } from 'components/SharedStyledComponents';
import { AnswerChoice, Cancer, Pages } from 'state/user-meta';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import PageFour from './PageFour';
import PageSix from './PageSix';
import PageFive from './PageFive';
import Logo, { LogoOptions } from 'components/interface/icons/Logo';
import { createProfile } from 'state/actions/UserActions';

const Onboarding: React.FC<RouteComponentProps> = (): JSX.Element => {
   const {
      state: {
         userState: { currentUser },
      },
   } = useContext(Store);
   const [activePage, setActivePage] = useState<number>(0);
   const [pagesComplete, setPagesComplete] = useState<Pages>({
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
   });

   const completeOnboarding = async (e: ButtonClick) => {
      e.preventDefault();
      try {
         await createProfile(currentUser.meta);
         navigate(`/recommended/`);
      } catch (error) {
         console.error(error);
      }
   };

   return (
      <Container>
         <Header>
            <OnboardingHeaderLg>Welcome</OnboardingHeaderLg>
            <OnboardingHeaderMd>let's get started!</OnboardingHeaderMd>
         </Header>
         <OnboardingFormContainer>
            <OnboardingForm>
               <PaginationWrapper activePage={activePage}>
                  <Page>
                     <PageOne
                        age={currentUser.meta.age}
                        gender={currentUser.meta.gender}
                        diagnosedWithCancer={currentUser.meta.diagnosedWithCancer}
                        setPagesComplete={setPagesComplete}
                     />
                  </Page>
                  <Page>
                     <PageTwo personal={currentUser.meta.personal} caregiver={currentUser.meta.caregiver} setPagesComplete={setPagesComplete} />
                  </Page>
                  <Page>
                     <PageThree
                        cancerOfInterest={currentUser.meta.personal ? currentUser.meta.diagnosis : currentUser.meta.focusedDiagnosis}
                        personal={currentUser.meta.personal}
                        setPagesComplete={setPagesComplete}
                     />
                  </Page>
                  <PageFour
                     personal={currentUser.meta.personal}
                     treatmentStatus={currentUser.meta.treatmentStatus}
                     requestedResources={currentUser.meta.requestedResources}
                     setPagesComplete={setPagesComplete}
                  />
                  <Page>
                     {currentUser.meta.personal && (
                        <PageFive focusedDiagnosis={currentUser.meta.focusedDiagnosis} setPagesComplete={setPagesComplete} />
                     )}
                  </Page>
                  <Page>
                     {currentUser.meta.personal && (
                        <PageSix requestedResources={currentUser.meta.requestedResources} setPagesComplete={setPagesComplete} />
                     )}
                  </Page>
               </PaginationWrapper>
            </OnboardingForm>
            <Pagination
               setActivePage={setActivePage}
               activePage={activePage}
               pagesComplete={pagesComplete}
               personal={currentUser.meta.personal}
               completeOnboarding={completeOnboarding}
            />
            <LogoContainer>
               <Logo logo={LogoOptions.footer} height={150} />
            </LogoContainer>
         </OnboardingFormContainer>
      </Container>
   );
};

interface PaginationProps {
   activePage: number;
   setActivePage: SetState<number>;
   pagesComplete: Pages;
   personal: boolean;
   completeOnboarding: (e: ButtonClick) => void;
}

const Pagination: React.FC<PaginationProps> = ({
   activePage,
   setActivePage,
   pagesComplete,
   personal,
   completeOnboarding,
}): JSX.Element | null => {
   switch (activePage) {
      case 0:
         return (
            <Flex flexDirection="row" justifyContent="center">
               <PageButton onClick={() => setActivePage(activePage + 1)} disabled={!pagesComplete.one}>
                  Next
               </PageButton>
            </Flex>
         );
      case 1:
         return (
            <Flex flexDirection="row" justifyContent="center">
               <PageButton onClick={() => setActivePage(activePage - 1)} margin="0 1.5em">
                  Previous
               </PageButton>
               <PageButton onClick={() => setActivePage(activePage + 1)} disabled={!pagesComplete.two} margin="0 1.5em">
                  Next
               </PageButton>
            </Flex>
         );
      case 2:
         return (
            <Flex flexDirection="row" justifyContent="center">
               <PageButton onClick={() => setActivePage(activePage - 1)} margin="0 1.5em">
                  Previous
               </PageButton>
               <PageButton onClick={() => setActivePage(activePage + 1)} disabled={!pagesComplete.three} margin="0 1.5em">
                  Next
               </PageButton>
            </Flex>
         );
      case 3:
         return (
            <Flex flexDirection="row" justifyContent="center">
               <PageButton onClick={() => setActivePage(activePage - 1)} margin={personal ? '0 1.5em' : 'inherit'}>
                  Previous
               </PageButton>
               {!personal && (
                  <CompleteButton onClick={completeOnboarding} disabled={!pagesComplete.four}>
                     Submit
                  </CompleteButton>
               )}
               {personal && (
                  <PageButton onClick={() => setActivePage(activePage + 1)} disabled={!pagesComplete.four} margin="0 1.5em">
                     Next
                  </PageButton>
               )}
            </Flex>
         );
      case 4:
         return (
            <Flex flexDirection="row" justifyContent="center">
               <PageButton onClick={() => setActivePage(activePage - 1)} margin="0 1.5em">
                  Previous
               </PageButton>
               <PageButton onClick={() => setActivePage(activePage + 1)} disabled={!pagesComplete.five} margin="0 1.5em">
                  Next
               </PageButton>
            </Flex>
         );
      case 5:
         return (
            <Flex flexDirection="row" justifyContent="center">
               <PageButton onClick={() => setActivePage(activePage - 1)} margin="0 1.5em">
                  Previous
               </PageButton>
               <CompleteButton onClick={completeOnboarding} disabled={!pagesComplete.six}>
                  Submit
               </CompleteButton>
            </Flex>
         );
      default:
         return null;
   }
};

export const renderDiagnosisCheckboxes = (
   meta: any,
   actionType: { cancerTypes: string; other: string },
   dispatch: (action: Action) => any,
   isDiagnosed: boolean
): AnswerChoice[] => {
   const checkboxes = [
      {
         label: 'Lung',
         checked: meta[Cancer.lung] ? meta[Cancer.lung].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.lung }),
      },
      {
         label: 'Breast',
         checked: meta[Cancer.breast] ? meta[Cancer.breast].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.breast }),
      },
      {
         label: 'Prostate',
         checked: meta[Cancer.prostate] ? meta[Cancer.prostate].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.prostate }),
      },
      {
         label: 'Colon/rectum',
         checked: meta[Cancer.colon_rectum] ? meta[Cancer.colon_rectum].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.colon_rectum }),
      },
      {
         label: 'Ovarian',
         checked: meta[Cancer.ovarian] ? meta[Cancer.ovarian].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.ovarian }),
      },
      {
         label: 'Cervical',
         checked: meta[Cancer.cervical] ? meta[Cancer.cervical].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.cervical }),
      },
      {
         label: 'Cervix',
         checked: meta[Cancer.cervix] ? meta[Cancer.cervix].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.cervix }),
      },
      {
         label: 'Kidney',
         checked: meta[Cancer.kidney] ? meta[Cancer.kidney].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.kidney }),
      },
      {
         label: 'Pancreatic',
         checked: meta[Cancer.pancreatic] ? meta[Cancer.pancreatic].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.pancreatic }),
      },
      {
         label: 'Liver',
         checked: meta[Cancer.liver] ? meta[Cancer.liver].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.liver }),
      },
      {
         label: 'Leukemia',
         checked: meta[Cancer.leukemia] ? meta[Cancer.leukemia].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.leukemia }),
      },
      {
         label: 'Non-Hodgkin lymphoma',
         checked: meta[Cancer.non_hodgkin_lymphoma] ? meta[Cancer.non_hodgkin_lymphoma].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.non_hodgkin_lymphoma }),
      },
   ];
   //if user has not had personal cancer diagnosis, display option for general cancer info
   !isDiagnosed &&
      checkboxes.unshift({
         label: 'Cancer in general',
         checked: meta[Cancer.general] ? meta[Cancer.general].checked : false,
         resolver: () => dispatch({ type: actionType.cancerTypes, payload: Cancer.general }),
      });

   return checkboxes;
};

//this component serves as a wrapper to the questions defined in Onboarding.tsx to serve as quasi-pagination. Each <Page /> component in Onboarding.tsx will be a member of the children array passed to Children.toArray()
//NOTE the return value is also tricky to type here
const PaginationWrapper: React.FC<{ children: ReactNodeArray; activePage: number }> = ({ children, activePage = 0 }): any =>
   Children.toArray(children)[activePage];

export default Onboarding;

const { Container, HeadingLg, HeadingMd, Header, FormContainer, Form, LogoContainer, LoginButton: ContinueButton } = OnboardingComponents;

const Page = styled.div``;

const CompleteButton = styled(ContinueButton).attrs(({ disabled }) => ({ disabled }))`
   background-color: ${({ theme, disabled }) => (disabled ? theme.secondaryGreen : theme.primaryGreen)};
   width: 80%;
   font-size: 1.4em;
   margin-top: 15px;
   &:hover {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      background-color: ${({ theme }) => theme.secondaryGreen};
   }
`;

const PageButton = styled(ContinueButton).attrs(({ name, disabled }) => ({ type: 'button', name, disabled }))`
   border-radius: 20px;
   padding: 9px 8px;
   background-color: ${({ theme, disabled = false }) => (disabled ? theme.secondaryOrange : theme.primaryOrange)};
   width: 25%;
   margin: ${({ margin = 'inherit' }: { margin?: string }) => margin};
   display: inline-block;
   color: ${({ theme, disabled = false }) => (disabled ? theme.primaryOffWhite : theme.primaryWhite)};

   @media only screen and (max-width: 400px) {
      width: 90px;
      margin: 0 1em;
      padding: 9px 7px;
   }

   &:hover {
      cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
   }
`;

const OnboardingHeaderLg = styled(HeadingLg)`
   font-size: 3.2em;
`;
const OnboardingHeaderMd = styled(HeadingMd)`
   font-size: 2.3em;
`;

const OnboardingFormContainer = styled(FormContainer)`
   margin: 0 auto;
`;

const OnboardingForm = styled(Form)`
   margin: 0 auto;
   text-align: left;
   width: 80%;

   @media only screen and (max-width: 400px) {
      width: 100%;
   }
`;

const OverflowIndenter = styled.span`
   display: inline-block;
   padding-left: 20px;
   text-indent: -10px;
   vertical-align: top;
`;
