import React, { useEffect } from 'react';
import RadioButton from 'components/interface/Form/RadioButton';
import { AnswerChoice } from 'state/user-meta';
import { QuestionLayout } from 'components/SharedStyledComponents';
import uuid from 'uuid';

interface Props {
   question?: string;
   answerChoices: AnswerChoice[];
}

const RadioButtonGroup: React.FC<Props> = ({ question, answerChoices }): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <QuestionContainer>
         {question && <QuestionHeader>{question}</QuestionHeader>}
         {answerChoices.map(
            ({ label, resolver, checked }: AnswerChoice): JSX.Element => (
               <RadioButton key={uuid.v4()} label={label} checked={checked} onChange={() => resolver()} />
            )
         )}
      </QuestionContainer>
   );
};

export default RadioButtonGroup;

const { QuestionHeader, QuestionContainer } = QuestionLayout;
