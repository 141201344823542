import React, { useContext, useEffect, useState } from 'react';
import Flex from 'Elements/Flex';
import styled from 'styled-components';
import Icon, { IconType } from '../icons/IconProvider';
import { Store } from 'state/store';
import { OPEN_SIDEBAR } from 'state/constants';
import { deriveTruth } from 'utility/ArrayUtils';
import { FilterKeys } from 'state/reducers/FIlterReducer';
const checkBoxData = { props: { target: 'checked', desired: 'id' } };

const SearchButtons = () => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);
   const [filterCount, setFilterCount] = useState<number>(0);

   const deriveAppliedFilterCount = (): number => {
      const purposeFilters: number[] = deriveTruth({ obj: filterState[FilterKeys.purpose], ...checkBoxData });
      const cancerFilters: number[] = deriveTruth({ obj: filterState[FilterKeys.cancer_type], ...checkBoxData });
      const evidenceBased: number = filterState[FilterKeys.evidence_based] ? 1 : 0;
      const platformFilters: number[] = deriveTruth({ obj: filterState[FilterKeys.platform], ...checkBoxData });
      const costBased: number = filterState[FilterKeys.cost] ? 1 : 0;
      return purposeFilters.length + cancerFilters.length + evidenceBased + platformFilters.length + costBased;
   };

   useEffect(() => {
      setFilterCount(deriveAppliedFilterCount());
   }, [
      filterState[FilterKeys.purpose],
      filterState[FilterKeys.cancer_type],
      filterState[FilterKeys.evidence_based],
      filterState[FilterKeys.platform],
      filterState[FilterKeys.cost],
   ]);

   return (
      <Flex flexDirection="row" justifyContent="flex-end">
         <Button onClick={() => dispatch({ type: OPEN_SIDEBAR, payload: true })}>
            <ButtonTextContainer filterCount={filterCount}>{filterCount > 0 ? `Filter (${filterCount})` : 'Filter'}</ButtonTextContainer>
            <ButtonTextContainerMobile filterCount={filterCount}>{filterCount > 0 ? `(${filterCount})` : ''}</ButtonTextContainerMobile>
            <Icon type={IconType.filter} color={filterCount > 0 ? '#CC8141' : 'hsl(0, 0%, 18%)'} />
         </Button>
      </Flex>
   );
};

export default SearchButtons;

const Button = styled.button.attrs({ type: 'button' })`
   border: 1px solid ${({ theme }) => theme.primaryGray};
   background-color: whitesmoke;
   color: ${({ theme }) => theme.almostBlack};
   padding: 5px 7px;
   /* margin: 0.5em; */
   font-weight: bold;
   font-size: 1.2em;
   display: flex;
   flex-direction: row;
   align-content: center;
   align-items: center;

   &:hover {
      cursor: pointer;
   }
   @media only screen and (max-width: 400px) {
      font-size: 0.8em;
   }
`;

const ButtonTextContainer = styled.span`
   margin-right: 10px;
   color: ${({ filterCount, theme }: { filterCount: number; theme: any }) => (filterCount > 0 ? theme.primaryOrange : theme.almostBlack)};
   @media only screen and (max-width: 400px) {
      display: none;
   }
`;
const ButtonTextContainerMobile = styled.span`
   color: ${({ filterCount, theme }: { filterCount: number; theme: any }) => (filterCount > 0 ? theme.primaryOrange : theme.almostBlack)};
   display: none;
   @media only screen and (max-width: 400px) {
      display: inline-block;
   }
`;
