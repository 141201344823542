import React, { Fragment, memo } from 'react';
import { Tag, Resource } from 'network/EstarApi/Resources';
import Icon, { IconType } from 'components/interface/icons/IconProvider';
import { Button } from 'components/SharedStyledComponents';
import Rating from './Rating';
import { prependApiUrl } from 'network/FetchWrapper';
import { toggleFavorite } from 'state/actions/UserActions';
import PlatformLinks from './PlatformLinks';
import styled from 'styled-components';
import { Link } from '@reach/router';
import Flex from 'Elements/Flex';

type ResourceProps = {
   fullView?: boolean;
};

const ResourceCard: React.FC<Resource & ResourceProps> = ({
   name,
   functionsFeatures,
   tags,
   publisher,
   appIcon,
   expertRating,
   id,
   favorite: isFavorite,
   purpose,
   platformlinks,
   notes,
   fullView = false,
}): JSX.Element => (
   <CardContainer>
      <CardHeader>
         {appIcon.url ? (
            <AppIconContainer>
               <img src={prependApiUrl(appIcon.url)} alt={`${name} logo`} height="100" width="100" />
            </AppIconContainer>
         ) : (
            <PlaceholderImage />
         )}
         <CardTitleContainer>
            <AppNameContainer>
               <Name>{name}</Name>
               {publisher.url ? (
                  <PublisherNameLink href={publisher.url}>{publisher.name}</PublisherNameLink>
               ) : (
                  <PublisherName>{publisher.name}</PublisherName>
               )}
               <PlatformLinks platforms={platformlinks} />
            </AppNameContainer>
         </CardTitleContainer>
         <InteractiveElements>
            {expertRating && (
               <ExpertRating>
                  <Rating rating={expertRating} />
               </ExpertRating>
            )}
            <Specs>
               <Price>Free</Price>
               <Icon
                  type={IconType.heart}
                  position="relative"
                  isButton={true}
                  color="#D7425A"
                  onClick={() => toggleFavorite(id, isFavorite)}
                  isFavorite={isFavorite}
               />
            </Specs>
         </InteractiveElements>
      </CardHeader>
      <DescriptionContainer>
         <Fragment>
            <Description fullView={fullView}>
               <p>{purpose}</p>
               {fullView && (
                  <Fragment>
                     <p>{functionsFeatures}</p>
                     <p>{notes}</p>
                  </Fragment>
               )}
            </Description>
         </Fragment>
         {fullView && (
            <Flex flexDirection="column" justifyContent="flex-start" margin="10px 0 8px 0" flexWrap="wrap" alignItems="flex-start" width="75%">
               {tags.map(
                  ({ id, shortTag }: Tag): JSX.Element => (
                     <TagSpan key={id}>{shortTag}</TagSpan>
                  )
               )}
            </Flex>
         )}
      </DescriptionContainer>
      {!fullView && (
         <Footer>
            <StyledLink to={`/resource/${id}`}>
               <Button color="primary-green" width="40%" margin="0 auto">
                  Learn More
               </Button>
            </StyledLink>
         </Footer>
      )}
   </CardContainer>
);

export default memo(ResourceCard);

const StyledLink = styled(Link).attrs(({ to }) => ({ to }))`
   text-decoration: none;
   color: inherit;
   width: 100%;
   text-align: center;
`;

const CardContainer = styled.div`
   border: 1px solid ${({ theme }) => theme.secondaryGray};
   border-radius: 4px;
   box-shadow: ${({ theme }) => theme.boxShadow};
   width: 100%;
   background-color: ${({ theme }) => theme.primaryWhite};
   padding: 5px 0;
   margin: 0.4em auto 1em auto;
`;

const CardHeader = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   padding: 20px;
   width: 100%;
`;

const CardTitleContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
`;

const AppNameContainer = styled.div`
   text-align: left;
   margin-left: 7px;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
`;

const Name = styled.h3`
   color: ${({ theme }) => theme.almostBlack};
   font-weight: 700;
   font-size: 1.2em;
   line-height: 1.1;
   text-align: left;
`;

const PublisherName = styled.p`
   font-weight: 500;
   color: ${({ theme }) => theme.almostBlack};
   font-size: 0.9em;
   line-height: 1.1;
   text-decoration: none;
   margin-top: 5px;
   &:hover {
      color: ${({ theme }) => theme.secondaryBlue};
   }

   @media only screen and (max-width: 400px) {
      color: ${({ theme }) => theme.secondaryBlue};
   }
`;

const PublisherNameLink = styled.a.attrs(({ href }) => ({ href }))`
   font-weight: 500;
   color: ${({ theme }) => theme.almostBlack};
   font-size: 0.9em;
   line-height: 1.1;
   text-decoration: none;
   margin-top: 5px;
   &:hover {
      color: ${({ theme }) => theme.secondaryBlue};
   }

   @media only screen and (max-width: 400px) {
      color: ${({ theme }) => theme.secondaryBlue};
   }
`;

const Specs = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-end;
`;

const Price = styled.small`
   line-height: 1;
   color: ${({ theme }) => theme.tertiaryGray};
   margin-bottom: 10px;
`;

const DescriptionContainer = styled.div`
   text-align: left;
   width: 100%;
   padding: 0 20px;
   margin-top: 0;
`;

const Description = styled.div`
   color: ${({ theme }) => theme.almostBlack};
   font-weight: 400;

   ${({ fullView }: { fullView: boolean }) =>
      `
      overflow: ${fullView ? 'visible' : 'hidden'};
      text-overflow: ${fullView ? 'clip' : 'ellipsis'};
      ${fullView ? '' : 'max-height: 130px'};
   `};
`;

const Footer = styled.div`
   background: ${({ theme }) => theme.primaryWhite};
   margin: 1em 0;
   border-top: 1px solid ${({ theme }) => theme.secondaryGray};
   display: flex;
   border-radius: 4px;
   align-items: center;
   flex-direction: row;
   justify-content: center;
   padding: 1.5em 0;
`;

const PlaceholderImage = styled.div`
   height: 120px;
   min-width: 120px;
   background: darkgray;

   @media only screen and (max-width: 680px) {
      height: 110px;
      min-width: 110px;
   }

   @media only screen and (max-width: 400px) {
      height: 80px;
      min-width: 80px;
   }
`;

const InteractiveElements = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: flex-start;
   width: 100px;
   margin-left: auto;
`;

const ExpertRating = styled.div`
   display: inline-block;
   width: 90px;
   padding: 0;
`;

const TagSpan = styled.span`
   margin: 0 0.5em;
   padding: 4px 5px;
   border-radius: 4px;
   font-weight: 600;
   font-size: 0.9em;
   color: white;
   background: ${({ theme }) => theme.secondaryBlue};
   &:first-child {
      margin-left: 0;
   }
`;

const AppIconContainer = styled.div`
   object-fit: cover;
   padding: 0;

   img {
      height: 100px;
      width: 100px;
      @media only screen and (max-width: 400px) {
         height: 70px;
         width: 70px;
      }
   }
`;
