import React, { useCallback, useContext, useEffect } from 'react';
import { QuestionLayout } from 'components/SharedStyledComponents';
import { InputChange, SetState } from 'types/events';
import { UserMetaEvents as actions } from 'state/constants';
import RadioButtonGroup from 'components/interface/Form/RadioButtonGroup';
import { Gender, Pages } from 'state/user-meta';
import { Store } from 'state/store';
import styled from 'styled-components';

/**
|--------------------------------------------------
| QUESTION CONTENTS
1) How old are you? -- number input
2) How do you describe yourself? (gender) -- radio button
3) Have you ever been diagnosed with cancer? -- radio button
|--------------------------------------------------
*/

interface Props {
   age: number | null;
   gender: { male: boolean; female: boolean; transgender: boolean };
   diagnosedWithCancer: boolean | null;
   setPagesComplete: SetState<Pages>;
}

const PageOne: React.FC<Props> = ({ age, gender, diagnosedWithCancer, setPagesComplete }): JSX.Element => {
   const { dispatch } = useContext(Store);

   const determinePageCompleteness = useCallback(() => {
      let completed: boolean;
      if (age && (gender.female || gender.male || gender.transgender) && diagnosedWithCancer !== null) {
         completed = true;
      } else {
         completed = false;
      }
      setPagesComplete((prevPages: Pages) => ({ ...prevPages, one: completed }));
   }, [age, gender, diagnosedWithCancer]);

   useEffect(() => {
      determinePageCompleteness();
   }, [age, gender, diagnosedWithCancer]);
   return (
      <QuestionContainer>
         <QuestionContainer>
            <Label htmlFor="age">
               <QuestionHeader>How old are you?</QuestionHeader>
            </Label>
            <AgeInput
               type="number"
               min="13"
               value={age || ''}
               max="100"
               onChange={(e: InputChange) => dispatch({ type: actions.SET_AGE, payload: Number(e.target.value) })}
            />
         </QuestionContainer>
         <RadioButtonGroup
            question="How would you describe yourself?"
            answerChoices={[
               {
                  label: 'Male',
                  checked: gender.male,
                  resolver: () => dispatch({ type: actions.SET_GENDER, payload: Gender.male }),
               },
               {
                  label: 'Female',
                  checked: gender.female,
                  resolver: () => dispatch({ type: actions.SET_GENDER, payload: Gender.female }),
               },
               {
                  label: 'Transgender',
                  checked: gender.transgender,
                  resolver: () => dispatch({ type: actions.SET_GENDER, payload: Gender.transgender }),
               },
            ]}
         />
         <RadioButtonGroup
            question="Have you ever been diagnosed with cancer?"
            answerChoices={[
               {
                  label: 'Yes',
                  checked: diagnosedWithCancer === true,
                  resolver: () => dispatch({ type: actions.SET_PREVIOUS_DIAGNOSIS, payload: true }),
               },
               {
                  label: 'No',
                  checked: diagnosedWithCancer === false,
                  resolver: () => dispatch({ type: actions.SET_PREVIOUS_DIAGNOSIS, payload: false }),
               },
            ]}
         />
      </QuestionContainer>
   );
};

export default PageOne;

const { QuestionContainer, QuestionHeader, Label } = QuestionLayout;

const AgeInput = styled.input.attrs(({ min, max }) => ({ type: 'number', min, max }))`
   padding: 10px;
   color: ${({ theme }) => theme.almostBlack};
   width: 100%;
   border-radius: 20px;
   border: 1px solid ${({ theme }) => theme.primaryGray};
   /* ::after {
      content: 'years';
   } */
`;
