import React, { useContext } from 'react';
import { Store } from 'state/store';
import { FilterContainer } from 'components/SharedStyledComponents';
import CheckboxGroup from '../Form/CheckboxGroup';
import { FilterEvents as actions } from 'state/constants';
import { FilterKeys } from 'state/reducers/FIlterReducer';

const privacyPolicyUrl = 'Must Include Privacy Policy';
const PrivacyPolicyUrl: React.FC<{ filterTarget: FilterKeys }> = ({ filterTarget }): JSX.Element => {
   const {
      state: { filterState },
      dispatch,
   } = useContext(Store);

   const handleCheckboxClick = (checked: boolean) => {
      dispatch({ type: actions.TOGGLE_FILTER, payload: { filterTarget, checked } });
      return dispatch({ type: actions.BUILD_FILTER_QUERY });
   };

   return (
      <FilterContainer>
         <CheckboxGroup
            answerChoices={[
               {
                  label: privacyPolicyUrl,
                  checked: filterState[filterTarget],
                  resolver: () => handleCheckboxClick(!filterState[filterTarget]),
               },
            ]}
         />
      </FilterContainer>
   );
};

export default PrivacyPolicyUrl;
