import React, { useContext, useEffect, Fragment, useState } from 'react';
import { UserMetaEvents as actions } from 'state/constants';
import { QuestionLayout } from 'components/SharedStyledComponents';
import { Store } from 'state/store';
import { CancerType, Pages } from 'state/user-meta';
import { SetState } from 'types/events';
import { renderDiagnosisCheckboxes } from './Onboarding';
import CheckboxGroup from 'components/interface/Form/CheckboxGroup';
import useInputValue from 'hooks/useInputValue';

/**
|--------------------------------------------------
| QUESTION CONTENTS
6) What is your cancer diagnosis, or the diagnosis in which you have interest? -- checkbox group
7) For which cancer diagnosis are you seeking information? -- checkbox group 
         Note: this question will ONLY BE VISIBLE if user selected "Someone else" for question 4 (Page 2)
|--------------------------------------------------
*/
interface Props {
   cancerOfInterest: CancerType;
   personal: boolean;
   setPagesComplete: SetState<Pages>;
}

const PageThree: React.FC<Props> = ({ personal, cancerOfInterest, setPagesComplete }) => {
   const { dispatch } = useContext(Store);
   const [isComplete, setIsComplete] = useState<boolean>(false);
   //if at least one of the boxes have been checked or the "other" input has a value
   const otherCancerOfInterest = useInputValue('');
   const determinePageCompleteness = () => {
      if (
         Object.values(cancerOfInterest).some(
            (cancerType: CancerType | any): boolean => cancerType.checked === true || otherCancerOfInterest.value !== ''
         )
      ) {
         setIsComplete(true);
      } else {
         setIsComplete(false);
      }
      setPagesComplete((prevPages: Pages) => ({ ...prevPages, three: isComplete }));
   };

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      determinePageCompleteness();
   }, [cancerOfInterest, personal, isComplete, otherCancerOfInterest.value]);

   return (
      <QuestionContainer>
         {personal === true ? (
            <Fragment>
               <CheckboxGroup
                  question="What is your cancer diagnosis, or the diagnosis in which you have interest? Check all that apply."
                  answerChoices={renderDiagnosisCheckboxes(
                     cancerOfInterest,
                     { cancerTypes: actions.SET_DIAGNOSIS, other: actions.SET_DIAGNOSIS_OTHER },
                     dispatch,
                     //user is diagnosed, therefore do not include "Cancer in general" option
                     true
                  )}
               />
               <OtherInputContainer>
                  <OtherTextInputLabel>Other (please specify)</OtherTextInputLabel>
                  <OtherTextInput
                     {...otherCancerOfInterest}
                     onBlur={() =>
                        otherCancerOfInterest.value !== '' &&
                        dispatch({ type: actions.SET_DIAGNOSIS_OTHER, payload: otherCancerOfInterest.value })
                     }
                  />
               </OtherInputContainer>
            </Fragment>
         ) : (
            <Fragment>
               <CheckboxGroup
                  question="For which cancer diagnosis are you seeking information? Check all that apply."
                  answerChoices={renderDiagnosisCheckboxes(
                     cancerOfInterest,
                     { cancerTypes: actions.SET_FOCUSED_DIAGNOSIS, other: actions.SET_FOCUSED_DIAGNOSIS_OTHER },
                     dispatch,
                     //user is NOT diagnosed, therefore include "Cancer in general" option
                     false
                  )}
               />
               <OtherInputContainer>
                  <OtherTextInputLabel>Other (please specify)</OtherTextInputLabel>
                  <OtherTextInput
                     {...otherCancerOfInterest}
                     onBlur={() =>
                        otherCancerOfInterest.value !== '' &&
                        dispatch({ type: actions.SET_FOCUSED_DIAGNOSIS_OTHER, payload: otherCancerOfInterest.value })
                     }
                  />
               </OtherInputContainer>
            </Fragment>
         )}
      </QuestionContainer>
   );
};

export default PageThree;

const { QuestionContainer, OtherInputContainer, OtherTextInput, OtherTextInputLabel } = QuestionLayout;
